import React from 'react';
import Slider from 'react-slick';
import CustomerFeedback from './CustomerFeedback';

function TestimonialsSlider({ sliderRef }) {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                },
            },
            {
                breakpoint: 680,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                },
            },
            {
                breakpoint: 420,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                },
            },
        ],
    };

    return (
        <div style={{ width: '100%', overflow: 'auto' }}>
            <Slider ref={sliderRef} {...settings}>
                {[...Array(9)].map((_, index) => (
                    <div key={index} className="px-2">
                        <CustomerFeedback />
                    </div>
                ))}
            </Slider>
        </div>
    );
}

export default TestimonialsSlider;
