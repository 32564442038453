import SignUpBanner from '../../assets/images/singUpBanner.png';
import React from 'react';

const AuthLayout = ({ children }) => {
    return (
        <div className={`container-fluid auth_bg d-flex justify-content-center align-items-center`}>
                <div className={`auth_child row align-items-center rounded-4 bg-white`}>
                    {/* <div className={`col-md-6 col-12 px-0 d-lg-block d-none`}>
                    <img src={SignUpBanner} alt="Banner" className={`w-100 ratio ratio-1x1`}/>
                </div> */}
                    <div
                        className={`col-12 justify-content-center mx-auto`}
                    >
                        <div className={`rounded-4 bg-white p-sm-5 py-5 px-2`}>
                           
                                {children}
                        </div>
                    </div>
                </div>
        </div>
    );
};

export default AuthLayout;
