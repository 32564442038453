import React from "react";

export const DateSvg = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_252_689)">
        <path
          d="M5.76094 0C5.23594 0 4.80094 0.435 4.80094 0.96V1.92H1.92094C1.66969 1.92 1.41469 2.01187 1.23469 2.19375C1.05281 2.37375 0.960938 2.62875 0.960938 2.88V22.08C0.960938 22.3313 1.05281 22.5863 1.23469 22.7681C1.41469 22.9481 1.66969 23.04 1.92094 23.04H22.0809C22.3322 23.04 22.5872 22.9481 22.7691 22.7681C22.9491 22.5863 23.0409 22.3313 23.0409 22.08V2.88C23.0409 2.62875 22.9491 2.37375 22.7691 2.19375C22.5872 2.01187 22.3322 1.92 22.0809 1.92H19.2009V0.96C19.2009 0.435 18.7659 0 18.2409 0H17.2809C16.7559 0 16.3209 0.435 16.3209 0.96V1.92H7.68094V0.96C7.68094 0.435 7.24594 0 6.72094 0H5.76094ZM5.76094 0.96H6.72094V3.84H5.76094V0.96ZM17.2809 0.96H18.2409V3.84H17.2809V0.96ZM1.92094 2.88H4.80094V3.84C4.80094 4.365 5.23594 4.8 5.76094 4.8H6.72094C7.24594 4.8 7.68094 4.365 7.68094 3.84V2.88H16.3209V3.84C16.3209 4.365 16.7559 4.8 17.2809 4.8H18.2409C18.7659 4.8 19.2009 4.365 19.2009 3.84V2.88H22.0809V6.24H1.92094V2.88ZM1.92094 7.2H22.0809V22.08H1.92094V7.2ZM4.80094 9.12V20.16H19.2009V9.12H4.80094ZM5.76094 10.08H8.16094V12.48H5.76094V10.08ZM9.12094 10.08H11.5209V12.48H9.12094V10.08ZM12.4809 10.08H14.8809V12.48H12.4809V10.08ZM15.8409 10.08H18.2409V12.48H15.8409V10.08ZM5.76094 13.44H8.16094V15.84H5.76094V13.44ZM9.12094 13.44H11.5209V15.84H9.12094V13.44ZM15.8409 13.44H18.2409V15.84H15.8409V13.44ZM5.76094 16.8H8.16094V19.2H5.76094V16.8ZM9.12094 16.8H11.5209V19.2H9.12094V16.8ZM12.4809 16.8H14.8809V19.2H12.4809V16.8ZM15.8409 16.8H18.2409V19.2H15.8409V16.8Z"
          fill="#8D9CB0"
        />
      </g>
      <defs>
        <clipPath id="clip0_252_689">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const LocationSvg = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0003 0.480469C7.75719 0.480469 4.32031 3.91734 4.32031 8.16047C4.32031 11.6686 6.17281 15.4392 7.99531 18.3605C9.81781 21.2817 11.6403 23.3555 11.6403 23.3555C11.7322 23.4586 11.8634 23.5186 12.0003 23.5186C12.1372 23.5186 12.2684 23.4586 12.3603 23.3555C12.3603 23.3555 14.1847 21.2386 16.0053 18.3005C17.8259 15.3623 19.6803 11.6011 19.6803 8.16047C19.6803 3.91734 16.2434 0.480469 12.0003 0.480469ZM12.0003 1.44047C15.7259 1.44047 18.7203 4.43484 18.7203 8.16047C18.7203 11.2486 16.9747 14.9198 15.1953 17.7905C13.7047 20.1961 12.4428 21.7448 12.0003 22.2755C11.5541 21.7486 10.2922 20.2336 8.80531 17.8505C7.02781 15.0005 5.28031 11.3236 5.28031 8.16047C5.28031 4.43484 8.27469 1.44047 12.0003 1.44047ZM12.0003 5.28047C10.1497 5.28047 8.64031 6.78984 8.64031 8.64047C8.64031 10.4911 10.1497 12.0005 12.0003 12.0005C13.8509 12.0005 15.3603 10.4911 15.3603 8.64047C15.3603 6.78984 13.8509 5.28047 12.0003 5.28047ZM12.0003 6.24047C13.3316 6.24047 14.4003 7.30922 14.4003 8.64047C14.4003 9.97172 13.3316 11.0405 12.0003 11.0405C10.6691 11.0405 9.60031 9.97172 9.60031 8.64047C9.60031 7.30922 10.6691 6.24047 12.0003 6.24047Z"
        fill="#8D9CB0"
      />
    </svg>
  );
};

export const ServicesSvg = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0799 1.44141C5.57805 1.44141 1.91992 5.09953 1.91992 9.60141C1.91992 14.1033 5.57805 17.7614 10.0799 17.7614C11.8612 17.7614 13.5074 17.1877 14.8499 16.2164L20.4891 21.8422C20.8625 22.2147 21.467 22.2143 21.8399 21.8414C22.2128 21.4685 22.2132 20.864 21.8407 20.4906L16.2749 14.9114C17.5012 13.4827 18.2399 11.6283 18.2399 9.60141C18.2399 5.09953 14.5818 1.44141 10.0799 1.44141ZM10.0799 2.40141C14.0624 2.40141 17.2799 5.61891 17.2799 9.60141C17.2799 13.5839 14.0624 16.8014 10.0799 16.8014C6.09742 16.8014 2.87992 13.5839 2.87992 9.60141C2.87992 5.61891 6.09742 2.40141 10.0799 2.40141Z"
        fill="#8D9CB0"
      />
    </svg>
  );
};

export const TimeSvg = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_252_693)">
        <path
          d="M12.0009 0.960938C5.9094 0.960938 0.960938 5.9094 0.960938 12.0009C0.960938 18.0925 5.9094 23.0409 12.0009 23.0409C18.0925 23.0409 23.0409 18.0925 23.0409 12.0009C23.0409 5.9094 18.0925 0.960938 12.0009 0.960938ZM12.0009 1.92094C17.5737 1.92094 22.0809 6.42822 22.0809 12.0009C22.0809 17.5737 17.5737 22.0809 12.0009 22.0809C6.42822 22.0809 1.92094 17.5737 1.92094 12.0009C1.92094 6.42822 6.42822 1.92094 12.0009 1.92094ZM11.9934 3.35437C11.8663 3.35636 11.7451 3.40871 11.6566 3.49993C11.568 3.59115 11.5192 3.71379 11.5209 3.84094V10.6444C11.2404 10.7436 10.9974 10.9273 10.8255 11.1702C10.6536 11.4131 10.5612 11.7033 10.5609 12.0009C10.5614 12.2155 10.6097 12.4272 10.7025 12.6206L7.82156 15.5016C7.77549 15.5458 7.73872 15.5988 7.71338 15.6574C7.68804 15.716 7.67466 15.7791 7.67401 15.843C7.67336 15.9068 7.68545 15.9702 7.70959 16.0293C7.73373 16.0884 7.76943 16.1421 7.81458 16.1873C7.85974 16.2324 7.91346 16.2681 7.97258 16.2923C8.03171 16.3164 8.09505 16.3285 8.15891 16.3279C8.22277 16.3272 8.28586 16.3138 8.34448 16.2885C8.40311 16.2632 8.45608 16.2264 8.50031 16.1803L11.3812 13.2994C11.5747 13.3921 11.7864 13.4405 12.0009 13.4409C12.3828 13.4409 12.7491 13.2892 13.0192 13.0192C13.2892 12.7491 13.4409 12.3828 13.4409 12.0009C13.4405 11.7035 13.348 11.4135 13.1761 11.1707C13.0042 10.928 12.7614 10.7445 12.4809 10.6453V3.84094C12.4818 3.77672 12.4698 3.71298 12.4456 3.65348C12.4214 3.59399 12.3855 3.53996 12.3401 3.49459C12.2946 3.44922 12.2405 3.41344 12.181 3.38935C12.1214 3.36527 12.0577 3.35337 11.9934 3.35437Z"
          fill="#8D9CB0"
        />
      </g>
      <defs>
        <clipPath id="clip0_252_693">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
