import { Spin } from "antd";
import React from "react";

const Loading = () => {
  return (
    <div className="d-flex justify-content-center align-items-center w-100 h-100vh">
      <Spin />
    </div>
  );
};

export default Loading;
