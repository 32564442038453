import React from 'react';
import { Menu } from 'antd';
import { NavLink, useHistory, useRouteMatch } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
    calendarIcon,
    catalogueIcon,
    createServices,
    customersIcon,
    dashboardIcon,
    messageIcon,
    moneyBag,
    review,
    setting,
    automatedMessage,
} from '../assets/images/sidebarIcons';

const MenuItems = ({ darkMode, toggleCollapsed, topMenu }) => {
    const { path } = useRouteMatch();
    const pathName = window.location.pathname;
    const pathArray = pathName.split(path);
    const mainPath = pathArray[1];
    const mainPathSplit = mainPath.split('/');
    const [openKeys, setOpenKeys] = React.useState(
        !topMenu
            ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`]
            : []
    );
    const history = useHistory();

    const onOpenChange = (keys) => {
        setOpenKeys(
            keys[keys.length - 1] !== 'recharts'
                ? [keys.length && keys[keys.length - 1]]
                : keys
        );
    };

    const onClick = (item) => {
        if (item.keyPath.length === 1) setOpenKeys([]);
    };

    const handleNavClick = (_path) => {
        history.push(`/dashboard/${_path}`);
    };
    const handlePricingClick = () => {
        history.push(`/pricing-plan`);
        toggleCollapsed();
    };

    const handleDashboardClick = () => {
        history.push(`/dashboard`);
        toggleCollapsed();
    };
    const handleStripeClick = () => {
        window.open('https://dashboard.stripe.com/login', '_blank');
    };

    return (
        <Menu
            onOpenChange={onOpenChange}
            onClick={onClick}
            mode={'inline'}
            theme={'dark'}
            // // eslint-disable-next-line no-nested-ternary
            defaultSelectedKeys={
                !topMenu
                    ? [
                        `${mainPathSplit.length === 1
                            ? 'home'
                            : mainPathSplit.length === 2
                                ? mainPathSplit[1]
                                : mainPathSplit[2]
                        }`,
                    ]
                    : []
            }
            defaultOpenKeys={
                !topMenu
                    ? [
                        `${mainPathSplit.length > 2
                            ? mainPathSplit[1]
                            : 'dashboard'
                        }`,
                    ]
                    : []
            }
            overflowedIndicator={<FeatherIcon icon="more-vertical" />}
            openKeys={openKeys}
        >
            <Menu.Item
                className={`${pathName === '/dashboard'
                        ? 'ant-menu-item-selected ant-menu-submenu-active'
                        : ''
                    }`}
                icon={
                    !topMenu && (
                        <NavLink
                            onClick={toggleCollapsed}
                            className="menuItem-iocn d-flex gap-2 flex-row-reverse text-decoration-none"
                            to={`/dashboard`}
                        >
                            <img
                                src={dashboardIcon}
                                className="menu-img"
                                style={{ width: 26 }}
                                alt="icon"
                            />
                            <p className="mb-0">Home</p>
                        </NavLink>
                    )
                }
                key="Dashboard"
                onClick={handleDashboardClick}
            ></Menu.Item>

            <Menu.Item
                className={`${pathName.includes('/dashboard/app/calendar')
                        ? 'ant-menu-item-selected ant-menu-submenu-active'
                        : ''
                    }`}
                icon={
                    !topMenu && (
                        <NavLink
                            onClick={toggleCollapsed}
                            className="menuItem-iocn d-flex gap-2 flex-row-reverse text-decoration-none"
                            to={`${path}/app/calendar/month`}
                        >
                            <img
                                src={calendarIcon}
                                className="menu-img"
                                style={{ width: 26 }}
                                alt="icon"
                            />
                            <p className="mb-0">Calendar</p>
                        </NavLink>
                    )
                }
                key="calender"
                onClick={() => handleNavClick('app/calendar/month')}
            ></Menu.Item>

            <Menu.Item
                className={`${pathName === '/dashboard/customer-history'
                        ? 'ant-menu-item-selected ant-menu-submenu-active'
                        : ''
                    }`}
                icon={
                    !topMenu && (
                        <NavLink
                            onClick={toggleCollapsed}
                            className="menuItem-iocn d-flex gap-2 flex-row-reverse text-decoration-none"
                            to={`${path}/customer-history`}
                        >
                            <img
                                src={customersIcon}
                                className="menu-img"
                                style={{ width: 26 }}
                                alt="icon"
                            />
                            <p className="mb-0">Customer History</p>
                        </NavLink>
                    )
                }
                key="customer-history"
                onClick={() => handleNavClick('customer-history')}
            ></Menu.Item>

            <Menu.Item
                className={`${pathName.includes('/dashboard/services')
                        ? 'ant-menu-item-selected ant-menu-submenu-active'
                        : ''
                    }`}
                icon={
                    !topMenu && (
                        <NavLink
                            onClick={toggleCollapsed}
                            className="menuItem-iocn d-flex gap-2 flex-row-reverse text-decoration-none"
                            to={`${path}/services`}
                        >
                            <img
                                src={createServices}
                                className="menu-img"
                                style={{ width: 26 }}
                                alt="icon"
                            />
                            <p className="mb-0">Services</p>
                        </NavLink>
                    )
                }
                key="services"
                onClick={() => handleNavClick('services')}
            ></Menu.Item>

            <Menu.Item
                className={`${pathName === '/pricing-plan'
                        ? 'ant-menu-item-selected ant-menu-submenu-active'
                        : ''
                    }`}
                icon={
                    !topMenu && (
                        <NavLink
                            onClick={toggleCollapsed}
                            className="menuItem-iocn d-flex gap-2 flex-row-reverse text-decoration-none"
                            to={`/pricing-plan`}
                        >
                            <img
                                src={catalogueIcon}
                                className="menu-img"
                                style={{ width: 26 }}
                                alt="icon"
                            />
                            <p className="mb-0">Pricing Plan</p>
                        </NavLink>
                    )
                }
                key="pricing-plan"
                onClick={handlePricingClick}
            ></Menu.Item>

            <Menu.Item
                className={`${pathName === '/dashboard/setting'
                        ? 'ant-menu-item-selected ant-menu-submenu-active'
                        : ''
                    }`}
                icon={
                    !topMenu && (
                        <NavLink
                            onClick={toggleCollapsed}
                            className="menuItem-iocn d-flex gap-2 flex-row-reverse text-decoration-none"
                            to={`${path}/setting`}
                        >
                            <img
                                src={setting}
                                className="menu-img"
                                style={{ width: 26 }}
                                alt="icon"
                            />
                            <p className="mb-0">Settings</p>
                        </NavLink>
                    )
                }
                key="Settings"
                onClick={() => handleNavClick('setting')}
            ></Menu.Item>

            {/* {user?.stripe_connect_id && ( */}
            <Menu.Item
                className={`${pathName === '/stripe/dashboard'
                        ? 'ant-menu-item-selected ant-menu-submenu-active'
                        : ''
                    }`}
                icon={
                    !topMenu && (
                        <div
                            onClick={toggleCollapsed}
                            className="d-flex gap-2 flex-row-reverse text-decoration-none justify-content-end"
                        >
                            <img
                                src={moneyBag}
                                className="menu-img"
                                style={{ width: 26 }}
                                alt="icon"
                            />
                            <p className="mb-0">Payments</p>
                        </div>
                    )
                }
                key="stripe"
                onClick={() => handleStripeClick()}
            ></Menu.Item>
            {/* )} */}

            <Menu.Item
                className={`${pathName === '/dashboard/chat'
                        ? 'ant-menu-item-selected ant-menu-submenu-active'
                        : ''
                    }`}
                icon={
                    !topMenu && (
                        <NavLink
                            onClick={toggleCollapsed}
                            className="menuItem-iocn d-flex gap-2 flex-row-reverse text-decoration-none"
                            to={`${path}/chat`}
                        >
                            <img
                                src={messageIcon}
                                className="menu-img"
                                style={{ width: 26 }}
                                alt="icon"
                            />
                            <p className="mb-0">Chat</p>
                        </NavLink>
                    )
                }
                key="business-chat"
                onClick={() => handleNavClick('chat')}
            ></Menu.Item>

            <Menu.Item
                className={`${pathName === '/dashboard/business-reviews'
                        ? 'ant-menu-item-selected ant-menu-submenu-active'
                        : ''
                    }`}
                icon={
                    !topMenu && (
                        <NavLink
                            onClick={toggleCollapsed}
                            className="menuItem-iocn d-flex gap-2 flex-row-reverse text-decoration-none"
                            to={`${path}/business-reviews`}
                        >
                            <img
                                src={review}
                                className="menu-img"
                                style={{ width: 26 }}
                                alt="icon"
                            />
                            <p className="mb-0">Reviews</p>
                        </NavLink>
                    )
                }
                key="bbusiness-reviews"
                onClick={() => handleNavClick('business-reviews')}
            ></Menu.Item>

            <Menu.Item
                className={`${pathName === '/dashboard/automated-messages'
                        ? 'ant-menu-item-selected ant-menu-submenu-active'
                        : ''
                    }`}
                icon={
                    !topMenu && (
                        <NavLink
                            onClick={toggleCollapsed}
                            className="d-flex gap-2 flex-row-reverse text-decoration-none"
                            to={`${path}/automated-messages`}
                        >
                            <img
                                src={automatedMessage}
                                className="menu-img"
                                style={{ width: 26 }}
                                alt="icon"
                            />
                            <p className="mb-0">Automated Messages</p>
                        </NavLink>
                    )
                }
                key="automated-messages"
                onClick={() => handleNavClick('automated-messages')}
            ></Menu.Item>
        </Menu>
    );
};

MenuItems.propTypes = {
    darkMode: propTypes.bool,
    topMenu: propTypes.bool,
    toggleCollapsed: propTypes.func,
};

export default MenuItems;
