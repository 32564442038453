import React from 'react';
import BreadCrum from '../breadcrum/BreadCrum';
import BusinessCards from '../../components/BusinessCards';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchAllFav } from '../../redux/services/servicesActions';
import Loading from '../../components/Loading';

const MyFavourites = () => {
    const dispatch = useDispatch();
    const { favourites, isFavLoading } = useSelector((state) => state.services);

    useEffect(() => {
        dispatch(fetchAllFav());
    }, []);

    return (
        <div className="container">
            <BreadCrum
                items={[
                    { title: 'Home', path: '/home' },
                    { title: 'My Favourites', path: '/customer/my-favourites' },
                ]}
            />
            <p className="text-center poly f-36 fw-normal c-344255 mb-4 ">
                My Favorites
            </p>

            {isFavLoading ? (
                <Loading />
            ) : (
                <div className={`row`}>
                    {favourites?.map((item, index) => {
                        return (
                            <div key={index} className='col-12 col-md-4 '>
                                <BusinessCards item={item?.business} type="favourite" />
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default MyFavourites;
