import React, { useState, useEffect, useRef } from 'react';
import logo from '../assets/images/Appointments/Logo-Header.png';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { destroySession, isAuthenticated } from '../helpers/functions';
import { persistor } from '../redux/store';
import AuthInfo from '../components/utilities/auth-info/info';
import { handleLogout } from '../redux/authentication/actionCreator';
import { useDispatch } from 'react-redux';

function Header() {
    const history = useHistory();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const headerDropdownRef = useRef(null);
    const dispatch = useDispatch();

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleSignout = async () => {
        destroySession();
        await persistor.purge();
        await dispatch(handleLogout());
        history.push('/login-customer');
    };

    const toggleDropdownOnClickOutside = (event) => {
        if (
            headerDropdownRef.current &&
            !headerDropdownRef.current.contains(event.target)
        ) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', toggleDropdownOnClickOutside);
        return () => {
            document.removeEventListener(
                'mousedown',
                toggleDropdownOnClickOutside
            );
        };
    }, []);

    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-light container pt-4 mb-2">
                <div className="container-fluid">
                    <Link className="navbar-brand" to="/home">
                        <img src={logo} alt="Logo" style={{ width: '146px' }} />
                    </Link>
                    {/* <button
                        className="navbar-toggler d-lg-none d-block border-0"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasDarkNavbar"
                        aria-controls="offcanvasDarkNavbar"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button> */}
                    <div
                        className="offcanvas offcanvas-end d-lg-none d-block w-50"
                        tabIndex="-1"
                        id="offcanvasDarkNavbar"
                        aria-labelledby="offcanvasDarkNavbarLabel"
                    >
                        <div className="offcanvas-header border-bottom">
                            <img
                                src={logo}
                                alt="Logo"
                                style={{ width: '76px', height: '22px' }}
                            />
                            <button
                                type="button"
                                className="btn-close btn-close"
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="offcanvas-body p-0">
                            <ul className="navbar-nav me-auto mb-lg-0">
                                <li className="nav-item py-2 text-center border-bottom">
                                    <a
                                        className="py-0 ps-0 pr-md-32 f-16 poppins c-667085 text-decoration-none mb-2"
                                        data-bs-dismiss="offcanvas"
                                        aria-current="page"
                                        onClick={() => {
                                            history.push(
                                                '/customer/profile-setting'
                                            );
                                            toggleDropdown();
                                        }}
                                    >
                                        Profile Settings
                                    </a>
                                </li>
                                <li className="nav-item py-2 text-center border-bottom">
                                    <a
                                        className="py-0 ps-0 pr-md-32 f-16 poppins c-667085 text-decoration-none mb-2"
                                        data-bs-dismiss="offcanvas"
                                        aria-current="page"
                                        onClick={() => {
                                            handleSignout();
                                        }}
                                    >
                                        Logout
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {isAuthenticated() ? (
                        <AuthInfo />
                    ) : (
                        <div className="d-flex gap-3">
                            <Link
                                className="btn btn-outline-light text-black rounded-pill border-0 text-decoration-underline f-16 fw-normal poppins py-md-11 px-md-20 my-2"
                                to={'/register-customer'}
                            >
                                Sign up
                            </Link>
                            <Link
                                className="btn btn-outline-light text-black rounded-pill border-0 text-decoration-underline f-16 fw-normal poppins py-md-11 px-md-20 my-2"
                                to={'/login-customer'}
                            >
                                Login
                            </Link>

                            <Link
                                className="btn btn-light rounded-pill f-16 fw-normal poppins py-md-11 px-md-20 my-2"
                                to={'/register'}
                            >
                                Create Business Account
                            </Link>
                        </div>
                    )}
                </div>
            </nav>
        </>
    );
}

export default Header;
