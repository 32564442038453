// import { useEffect, useState } from 'react';
import { Rating } from 'react-simple-star-rating';
import moment from 'moment';
import avatar from '../../assets/images/avatar.png';
// import { Spin } from 'antd';
// import { averageRating } from '../../helpers/constants';
// import { GrayStar } from '../home/svgs';

const BusinessReviewsCard = ({ item }) => {
    // const [activeIndex, setActiveIndex] = useState(null);

    // const handleCardClick = (index, item) => {
    //     setActiveIndex(index);
    //     setReviewDetails(item);
    // };

    // useEffect(() => {
    //     if (customers?.length > 0) {
    //         handleCardClick(0, customers[0]);
    //     }
    // }, [customers]);

    return (
        <div className="main-cont">
            <div className="d-flex flex-row flex-sm-column gap-3 h-100 mb-3">
                <div
                    className={`business-review-card 
                    `}
                >
                    <div className={`activebar`} />
                    <div className="d-flex flex-column justify-content-between w-100 px-3">
                        <div className="d-flex flex-column gap-2">
                            <div className="d-flex align-items-center gap-2">
                                <img
                                    style={{
                                        objectPosition: 'center',
                                        objectFit: 'cover',
                                    }}
                                    className="rounded-circle"
                                    src={
                                        item?.user?.profile_photo_path || avatar
                                    }
                                    alt="avatar"
                                    width={45}
                                    height={45}
                                />
                                <p className="poly f-18 c-536784 m-0 texy-truncate">
                                    {item?.user?.name}
                                </p>
                            </div>

                            <p className="poppins f-14 c-667085 m-0">
                                {item?.description}
                            </p>
                        </div>

                        <div>
                            <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
                                <Rating
                                    // initialValue={item?.appointments[0]?.review?.rating}
                                    initialValue={item?.rating}
                                    allowFraction
                                    fillColor="#FFC931"
                                    size={18}
                                    readonly
                                />

                                <p className="poppins f-12 c-667085 m-0 mt-2 mt-sm-0">
                                    {moment(item?.createdAt).format('LL')}
                                </p>
                            </div>

                            <p className="poppins f-10 c-667085 m-0">
                                Sent to you
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BusinessReviewsCard;
