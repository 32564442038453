import { Progress } from 'antd';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { TopMenuSearch } from '../layout/style';
import NotificationBox from './utilities/auth-info/notification';
import AuthInfo from './utilities/auth-info/info';

const BusinessHeader = ({ handlePopupClick }) => {
    const { userData } = useSelector((state) => state.user);
    const [percentage, setPercentage] = useState(0);

    const profileCompletion = userData?.business?.profileCompletion || [];

    const socialLink = profileCompletion.includes('social');
    const website = profileCompletion.includes('website');
    const services = profileCompletion.includes('service');
    const workingHours = profileCompletion.includes('working hours');

    useEffect(() => {
        let completionCount = 0;
        if (socialLink) completionCount++;
        if (website) completionCount++;
        if (services) completionCount++;
        if (workingHours) completionCount++;

        setPercentage((completionCount / 4) * 100);
    }, [socialLink, website, services, workingHours]);

    return (
        <div className={`container-fluid`}>
            <div className={`row justify-content-between gap-3`}>
                <div className={`col-auto ps-xl-0 ps-md-2 px-0 order-sm-1 order-2`}>
                    {/* <form action="">
                        <label
                            className={`label-search w-100 px-lg-4 px-md-2`}
                            htmlFor={'header-search'}
                        >
                            <div className={`container-fluid`}>
                                <div className={`row align-items-center`}>
                                    <div className={`col-auto px-0 lh-0`}>
                                        <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M17.9419 17.0595L14.0302 13.1485C15.1639 11.7874 15.7293 10.0415 15.6086 8.27409C15.488 6.5067 14.6906 4.85387 13.3823 3.65943C12.074 2.46499 10.3557 1.8209 8.58462 1.86115C6.81357 1.9014 5.12622 2.62289 3.87358 3.87554C2.62094 5.12818 1.89945 6.81553 1.8592 8.58657C1.81895 10.3576 2.46304 12.076 3.65748 13.3843C4.85192 14.6925 6.50475 15.4899 8.27214 15.6106C10.0395 15.7312 11.7854 15.1659 13.1466 14.0321L17.0575 17.9438C17.1156 18.0019 17.1845 18.048 17.2604 18.0794C17.3363 18.1108 17.4176 18.127 17.4997 18.127C17.5818 18.127 17.6631 18.1108 17.739 18.0794C17.8149 18.048 17.8838 18.0019 17.9419 17.9438C17.9999 17.8858 18.046 17.8168 18.0774 17.741C18.1089 17.6651 18.125 17.5838 18.125 17.5016C18.125 17.4195 18.1089 17.3382 18.0774 17.2623C18.046 17.1865 17.9999 17.1175 17.9419 17.0595ZM3.12469 8.75164C3.12469 7.63912 3.45459 6.55159 4.07267 5.62656C4.69076 4.70153 5.56926 3.98056 6.5971 3.55482C7.62493 3.12908 8.75593 3.01768 9.84707 3.23473C10.9382 3.45177 11.9405 3.9875 12.7272 4.77417C13.5138 5.56084 14.0496 6.56312 14.2666 7.65426C14.4837 8.7454 14.3723 9.8764 13.9465 10.9042C13.5208 11.9321 12.7998 12.8106 11.8748 13.4287C10.9497 14.0467 9.86221 14.3766 8.74969 14.3766C7.25836 14.375 5.82858 13.7818 4.77404 12.7273C3.71951 11.6728 3.12634 10.243 3.12469 8.75164Z"
                                                fill="black"
                                            />
                                        </svg>
                                    </div>
                                    <div className={`col-auto px-0`}>
                                        <input
                                            className={`input-search`}
                                            type="text"
                                            name="search"
                                            id={`header-search`}
                                            placeholder={`Search`}
                                        />
                                    </div>
                                </div>
                            </div>
                        </label>
                    </form> */}
                </div>

                <div className={`col-sm-auto col-12 px-0 order-sm-2 order-1`}>
                    <div className={`container-fluid`}>
                        <div className={`row`}>
                            <div className='col-md-auto d-flex align-items-center gap-2 justify-content-between flex-wrap'>
                                <div
                                    className={`complete-step`}
                                    onClick={handlePopupClick}
                                >
                                    <div
                                        className={`d-flex align-items-center`}
                                    >
                                        <div className={`col-auto px-0 d-flex align-items-center`}>
                                            <Progress
                                                type="circle"
                                                width={32}
                                                strokeColor={'#000'}
                                                percent={percentage}
                                            />
                                        </div>
                                        <div className={`col-auto px-10px`}>
                                            <span
                                                className={`f-13 fw-medium c-0f151d`}
                                            >
                                                Complete Setup
                                            </span>
                                        </div>
                                        <div className={`col-auto px-0`}>
                                            <svg
                                                width="18"
                                                height="18"
                                                viewBox="0 0 18 18"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M7.14828 2.97672L12.7733 8.60172C12.8256 8.65396 12.8671 8.716 12.8954 8.78428C12.9237 8.85257 12.9383 8.92577 12.9383 8.99969C12.9383 9.07361 12.9237 9.1468 12.8954 9.21509C12.8671 9.28338 12.8256 9.34541 12.7733 9.39766L7.14828 15.0227C7.04273 15.1282 6.89958 15.1875 6.75031 15.1875C6.60105 15.1875 6.45789 15.1282 6.35234 15.0227C6.2468 14.9171 6.1875 14.774 6.1875 14.6247C6.1875 14.4754 6.2468 14.3323 6.35234 14.2267L11.5801 8.99969L6.35234 3.77265C6.30008 3.72039 6.25862 3.65835 6.23034 3.59007C6.20206 3.52178 6.1875 3.4486 6.1875 3.37469C6.1875 3.30078 6.20206 3.22759 6.23034 3.15931C6.25862 3.09102 6.30008 3.02898 6.35234 2.97672C6.40461 2.92445 6.46665 2.883 6.53493 2.85471C6.60322 2.82643 6.6764 2.81187 6.75031 2.81187C6.82422 2.81187 6.89741 2.82643 6.96569 2.85471C7.03398 2.883 7.09602 2.92445 7.14828 2.97672Z"
                                                    fill="#0F151D"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <TopMenuSearch className={`d-md-none d-block`}>
                                    <div className="d-flex gap-3 align-items-center justify-content-end">
                                        <NotificationBox />
                                        <AuthInfo />
                                    </div>
                                </TopMenuSearch>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BusinessHeader;
