import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Customer = lazy(() => import('../../container/customer'));

function CustomerRoutes() {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route path={path} component={Customer} />
        </Switch>
    );
}

export default CustomerRoutes;
