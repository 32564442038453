import { axiosInstance } from '../../repository/Repository';
import { REVIEWS_PENDING, GET_REVIEWS, REVIEWS_REJECTED, POST_REVIEWS, GET_RECENT_REVIEWS } from './types';

export const postReview = (payload) => async (dispatch) => {
    dispatch({ type: REVIEWS_PENDING });
    try {
        const response = await axiosInstance.post('/reviews/add', payload);
        dispatch({ type: POST_REVIEWS });
        console.log('ReviewPosted', response);
        return response;
    } catch (error) {
        console.log('Error while posting review');
        dispatch({ type: REVIEWS_REJECTED });
    }
};

export const getReviews = (id) => async (dispatch) => {
    dispatch({ type: REVIEWS_PENDING });
    try {
        const response = await axiosInstance.get(`/reviews/${id}`);
        dispatch({ type: GET_REVIEWS, payload: response.data });
        console.log('GetReviews', response.data);
        return response;
    } catch (error) {
        console.log('Error while getting reviews');
        dispatch({ type: REVIEWS_REJECTED });
    }
};

export const getRecentReviews = (id) => async (dispatch) => {
    dispatch({ type: REVIEWS_PENDING });
    try {
        const response = await axiosInstance.get(`/reviews/recent/${id}`);
        dispatch({ type: GET_RECENT_REVIEWS, payload: response.data });
        console.log('GetRecentReviews', response.data);
        return response;
    } catch (error) {
        console.log('Error while getting recent reviews');
        dispatch({ type: REVIEWS_REJECTED });
    }
};