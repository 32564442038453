import React, { useEffect, useState } from 'react';
import { TopMenuSearch } from '../layout/style';
import AuthInfo from '../components/utilities/auth-info/info';
import NotificationBox from '../components/utilities/auth-info/notification';
import SetupGuide from '../components/setup-guide/SetupGuide';
import BusinessHeader from '../components/BusinessHeader';
import { notification } from '../assets/images/sidebarIcons';
import { useDispatch } from 'react-redux';
import { getNotifications } from '../redux/notifications/notificationsAction';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Heading from '../components/heading/heading';
import { Badge } from 'antd';

const Navbar = () => {
    const dispatch = useDispatch();
    const { notifications, isLoading } = useSelector(
        (state) => state.notifications
    );

    const [openPopup, setOpenPopup] = useState(false);
    const handlePopupClick = () => {
        setOpenPopup(!openPopup);
    };

    useEffect(() => {
        dispatch(getNotifications());
    }, []);

    return (
        <div className={`container-fluid bg-white`}>
            <div className={`row justify-content-between pb-30`}>
                <div
                    className={`col-lg-9 col-md-7 col-12 px-md-24 pt-24 px-0px`}
                >
                    <BusinessHeader handlePopupClick={handlePopupClick} />
                </div>
                <div className={`col-lg-3 col-md-5 col-12 px-md-24 pt-md-24px`}>
                    <TopMenuSearch className={`d-md-block d-none`}>
                        <div className="d-flex gap-3 align-items-center justify-content-end position-relative">
                            <NotificationBox />
                            <AuthInfo />
                        </div>
                    </TopMenuSearch>
                </div>
            </div>

            <SetupGuide
                openPopup={openPopup}
                handlePopupClick={handlePopupClick}
            />
        </div>
    );
};

export default Navbar;
