import React from 'react';
import { Star } from './svgs';
import client from '../../assets/images/client.png';
import { quote } from '../../assets/images/sidebarIcons';

const CustomerFeedback = () => {
    return (
        <div className={`slider-card p-40`}>
            <div className="carousel-item-quote">
                <img src={quote} alt="icon" />
            </div>
            <div className={`container-fluid pb-15`}>
                <div className={`row`}>
                    {[1, 2, 3, 4, 5].map((item, index) => (
                        <div key={index} className={`col-auto ps-0 pr-4`}>
                            <Star />
                        </div>
                    ))}
                </div>
            </div>
            <h5 className={`m-0 c-0f151d poppins f-16 fw-medium lh-24 pb-14`}>
                The best booking system
            </h5>
            <h6 className={`m-0 c-667085 poppins f-14 fw-normal lh-26 pb-35`}>
                Great experience, easy to book. Paying for treatments is so
                convenient — no cash or cards needed!
            </h6>

            <div className={`container-fluid`}>
                <div className={`row`}>
                    <div className={`col-auto ps-0 pr-20`}>
                        <img
                            src={client}
                            alt="Client"
                            className={`img-fluid br-50`}
                        />
                    </div>
                    <div className={`col px-0px`}>
                        <div className={`container-fluid`}>
                            <div className={`row`}>
                                <div className={`col-12 px-0px pb-6`}>
                                    <h5
                                        className={`m-0 c-0f151d poppins f-16 fw-medium lh-24`}
                                    >
                                        Cheyenne Stanton
                                    </h5>
                                </div>
                                <div className={`col-12 px-0px`}>
                                    <h6
                                        className={`m-0 c-667085 poppins f-14 fw-normal lh-20`}
                                    >
                                        Cheyenne Stanton
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomerFeedback;
