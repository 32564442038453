import { toast } from 'react-toastify';
import { axiosInstance } from '../../repository/Repository';

import {
    BOOK_APPOINTMENT_ERROR,
    BOOK_APPOINTMENT_START,
    BOOK_APPOINTMENT_SUCCESS,
    GET_BUSINESS_APP_ERROR,
    GET_BUSINESS_APP_START,
    GET_BUSINESS_APP_SUCCESS,
    GET_APPOINTMENTS,
    PENDING_APPOINTMENTS,
    REJECTED_APPOINTMENTS,
    CANCEL_APPOINTMENT_LOADING,
    PREVIOUS_APP_PENDING,
    PREVIOUS_APP_SUCCESS,
    PREVIOUS_APP_ERROR,
    RESCHEDULE_APPOINTMENT_LOADING,
} from './types';
import moment from 'moment';
import { getAllBusinessAppointments } from '../calendar/actionCreator';

// =============BOOK APPOINTMENT=================
export const createAppointment =
    (data, onSuccess = () => {}, onError = () => {}) =>
    async (dispatch) => {
        dispatch({ type: BOOK_APPOINTMENT_START });
        try {
            const response = await axiosInstance.post(
                '/appointments/add',
                data
            );
            console.log(response);
            dispatch({ type: BOOK_APPOINTMENT_SUCCESS });
            toast.success('Appointment booked Successfully');
            onSuccess();
        } catch (error) {
            onError();
            toast.error(
                error?.data?.message ||
                    'There was an error! please try again later'
            );
            dispatch({ type: BOOK_APPOINTMENT_ERROR });
        }
    };

// =============GET ALL BUSINESS APPOINTMENT=================
export const getBusinessAppointments = () => async (dispatch) => {
    dispatch({ type: GET_BUSINESS_APP_START });
    try {
        const response = await axiosInstance.get('/appointments/business/upcoming');
        let data = response.data?.data[0];
        let currentDate = moment().format('YYYY-MM-DD');

        let todayAppointments = data.filter((item) =>
            moment(item.date).isSame(currentDate, 'day')
        );
        let upcomingAppointments = data.filter(
            (item) => !moment(item.date).isSame(currentDate, 'day')
        );

        dispatch({
            type: GET_BUSINESS_APP_SUCCESS,
            payload: { todayAppointments, upcomingAppointments },
        });
    } catch (error) {
        toast.error('There was an error! please try again later');
        dispatch({ type: GET_BUSINESS_APP_ERROR });
    }
};

// =============GET CUSTOMER APPOINTMENTS=================
export const getCustomerAppointments = () => async (dispatch) => {
    dispatch({ type: PENDING_APPOINTMENTS });
    try {
        const response = await axiosInstance.get('appointments/customer');
        dispatch({ type: GET_APPOINTMENTS, payload: response.data.data });
        // console.log('GetResponse: ', response.data.data);
        return response.data;
    } catch (error) {
        console.log('Error Fetching Business Details');
        dispatch({ type: REJECTED_APPOINTMENTS });
        console.log(error);
    }
};

export const getPreviousAppointments = () => async (dispatch) => {
    dispatch({ type: PREVIOUS_APP_PENDING });
    try {
        const response = await axiosInstance.get(
            'appointments/customer/previous'
        );
        dispatch({ type: PREVIOUS_APP_SUCCESS, payload: response.data.data });
        return response.data;
    } catch (error) {
        console.log('Error Fetching Business Details');
        dispatch({ type: PREVIOUS_APP_ERROR });
        console.log(error);
    }
};

export const setCancelAppointmentLoader = (val) => async (dispatch) => {
    dispatch({ type: CANCEL_APPOINTMENT_LOADING, payload: val });
};

export const cancelAppointment =
    (id, onSuccess = () => {}) =>
    async (dispatch) => {
        try {
            dispatch(setCancelAppointmentLoader(true));
            await axiosInstance.put(`/appointments/customer/cancel/${id}`);
            dispatch(setCancelAppointmentLoader(false));
            dispatch(getCustomerAppointments())
            dispatch(getPreviousAppointments())
            toast.success('Appointment cancelled successfully');
            onSuccess();
        } catch (error) {
            toast.error(
                error?.data?.message ||
                    'Unable to cancel this appointment'
            );
            dispatch(setCancelAppointmentLoader(false));
        }
    };

// =============EDIT APPOINTMENT FROM BUSINESS SIDE=================
export const setRescheduleAppointmentLoader = (val) => async (dispatch) => {
    dispatch({ type: RESCHEDULE_APPOINTMENT_LOADING, payload: val });
};

export const rescheduleAppointment =
    (payload, onSuccess = () => {}) =>
    async (dispatch) => {
        try {
            dispatch(setRescheduleAppointmentLoader(true));
            await axiosInstance.put('/appointments/reschedule', payload);
            await dispatch(getAllBusinessAppointments());
            dispatch(setRescheduleAppointmentLoader(false));
            toast.success('Appointment edited successfully');
            onSuccess();
        } catch (error) {
            toast.error(
                error?.response?.data?.message ||
                    'Unable to edit this appointment'
            );
            dispatch(setRescheduleAppointmentLoader(false));
        }
    };
