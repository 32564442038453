import React, { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import upload from '../../assets/images/upload.png';
import AuthLayout from './auth-layout';
import { Spin } from 'antd';
import { useDispatch } from 'react-redux';
import { businessSignUp } from '../../redux/authentication/actionCreator';
import { useSelector } from 'react-redux';
import {
    alphabetsRegex,
    numericRegex,
    options,
    validateEmail,
    validatePassword,
} from '../../helpers/constants';
import { handleTempData } from '../../redux/users/actionCreator';
import logo from '../../assets/images/Appointments/Logo-Header.png';

const Register = () => {
    const isLoading = useSelector((state) => state.auth.loading);

    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();


    const queryParams = new URLSearchParams(location.search);
    const referral = queryParams.get('referral');

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [photo, setPhoto] = useState(null);
    const [previewImage, setPreviewImage] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [country, setCountry] = useState('United Kingdom');
    const [countryCode, setCountryCode] = useState('+44');
    const [searchTerm, setSearchTerm] = useState('');
    const [searchCountry, setSearchCountry] = useState('');
    const [photoError, setPhotoError] = useState('');
    const [terms, setTerms] = useState(false);
    const [errors, setErrors] = useState({});

    const filteredOptions = options.filter((country) =>
        country.label.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const filteredCountries = options.filter((country) =>
        country.label.toLowerCase().includes(searchCountry.toLowerCase())
    );

    const handleCountryClick = (event) => {
        event.preventDefault();
        console.log(event.target.textContent);
        setCountry(event.target.textContent);
    };

    const handleTermsClick = (url) => {
        window.open(url, '_blank');
    };

    const handleCountryCodeClick = (_country) => {
        setCountryCode(_country.code);
    };

    const handleFileChange = (event) => {
        setPhotoError('');
        const file = event.target.files[0];

        const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];

        if (!allowedTypes.includes(file.type)) {
            setPhotoError('You can only upload an image file (JPEG, PNG, JPG)');
            event.target.value = '';
            setPreviewImage('');
            return;
        }

        if (file.size > 20000000) {
            setPhotoError('File size exceeds 20 megabytes.');
            event.target.value = '';
            setPreviewImage('');
            return;
        }

        setPhoto(file);

        const reader = new FileReader();
        reader.onloadend = () => {
            setPreviewImage(reader.result);
        };
        reader.readAsDataURL(file);
    };

    const resetStates = () => {
        setName('');
        setEmail('');
        setPassword('');
        setPhone('');
        setCountry('');
        setTerms(false);
        setPhoto(null);
        setPreviewImage('');
    };

    const handleCredentials = (e) => {
        const { name, value } = e.target;

        if (name === 'email') {
            setEmail(value);
            if (validateEmail(value, setErrors)) {
                setErrors((prev) => ({ ...prev, [name]: '' }));
            }
        } else if (name === 'password') {
            setPassword(value);
            if (validatePassword(value, setErrors)) {
                setErrors((prev) => ({ ...prev, [name]: '' }));
            }
        }
    };

    const handleInputs = (e) => {
        const { name, value, checked } = e.target;

        const updateErrors = (errorCondition, errorMessage) => {
            setErrors((prev) => ({
                ...prev,
                [name]: errorCondition ? errorMessage : '',
            }));
        };

        switch (name) {
            case 'phone':
                if (numericRegex.test(value)) {
                    setPhone(value);
                    updateErrors(!value, 'Enter your mobile number');
                }
                break;
            case 'name':
                if (alphabetsRegex.test(value)) {
                    setName(value);
                    updateErrors(!value, `Enter your name`);
                }
                break;
            case 'terms':
                setTerms(checked);
                updateErrors(!checked, 'You have to agree to proceed');
                break;
            default:
                break;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors({});
        const [isEmailValid, isPasswordValid] = await Promise.all([
            validateEmail(email, setErrors),
            validatePassword(password, setErrors),
        ]);

        const updateError = (field, message) => {
            setErrors((prev) => ({ ...prev, [field]: message }));
        };
        if (!name) updateError('name', 'Name is required');
        if (!phone) {
            updateError('phone', 'Mobile number is required');
        } else if (phone.length < 6 || phone.length > 14)
            updateError(
                'phone',
                'Mobile number must be between 6 to 14 digits'
            );
        if (!country) updateError('country', 'Country is required');
        if (!terms) updateError('terms', 'You have to agree to proceed');

        if (
            !name ||
            !phone ||
            !country ||
            !terms ||
            phone.length < 6 ||
            phone.length > 14 ||
            !isEmailValid ||
            !isPasswordValid
        ) {
            return;
        }

        const formData = new FormData();
        formData.append('name', name);
        formData.append('email', email);
        formData.append('country', country);
        formData.append('phone', countryCode + '-' + phone);
        formData.append('terms', terms);
        formData.append('password', password);
        formData.append('image', photo);

        dispatch(
            businessSignUp(formData ,referral, (res) => {
                console.log('res', res);
                let additionalData = {
                    email,
                    password,
                    user_id: res?.data[0]?.id,
                };
                dispatch(handleTempData(additionalData));
                history.push(
                    `/business-info/${res?.data[0]?.reset_password_code}`
                    // additionalData
                );
            })
        );
    };

    return (
        <AuthLayout>
            <Link className="d-flex justify-content-center mb-4" to="/home">
                <img src={logo} alt="Logo" style={{ width: '132px' }} />
            </Link>
            <div className="d-flex flex-column gap-2 mb-5">
                <h5 className="c-334155 poly f-36 fw-400 lh-48 m-0 text-center">
                    Create a Business Account
                </h5>

                <p className="c-667085 poppins f-14 fw-400 lh-20 m-0 text-center">
                    Partner with us to bring better connectivity to your
                    customers
                </p>
            </div>

            {/* <div className="col-6 px-0 text-center pb-20 position-relative">
        <label
          htmlFor="formFile"
          className="form-label wh-102-97 box-sizing-content-box cp"
        >
          {previewImage ? (
            <img
              src={previewImage}
              alt="Preview"
              className="ratio ratio-1x1 rounded-circle h-100"
            />
          ) : (
            <img
              src={upload}
              alt="Choose file"
              className="img-fluid wh-102-97"
            />
          )}
          <input
            className="form-control d-none position-absolute"
            type="file"
            name="photo"
            id="formFile"
            onChange={handleFileChange}
          />
        </label>
        {photoError && <p className={`text-danger fs-12`}>{photoError}</p>}
      </div>

      {message && (
        <div className={` success-badge`}>
          <span>{message}!</span>
        </div>
      )} */}
            <form onSubmit={handleSubmit}>
                <div className={`col-12 px-md-0 position-relative `}>
                    <div className="mb-20">
                        <label
                            htmlFor="name"
                            className="form-label c-667085 poppins f-14 fw-400 lh-20"
                        >
                            Your Name <span className="text-danger">*</span>
                        </label>
                        <input
                            type="text"
                            name="name"
                            value={name}
                            onChange={handleInputs}
                            className="form-control input-filed"
                            id="name"
                            placeholder="Your Name"
                        />

                        {errors.name && (
                            <p className="text-danger fs-12">{errors.name}</p>
                        )}
                    </div>
                </div>

                <div className="col-12 px-md-0 position-relative">
                    <div className="mb-20">
                        <label
                            htmlFor="email"
                            className="form-label c-667085 poppins f-14 fw-400 lh-20"
                        >
                            Email Address <span className="text-danger">*</span>
                        </label>
                        <input
                            type="email"
                            className="form-control input-filed"
                            id="email"
                            name="email"
                            value={email}
                            onChange={handleCredentials}
                            placeholder="Your Email"
                        />

                        {errors.email && (
                            <p className="text-danger fs-12">{errors.email}</p>
                        )}
                    </div>
                </div>

                <div className="col-12 px-md-0 position-relative">
                    <div className="mb-20">
                        <label
                            htmlFor="password"
                            className="form-label c-667085 poppins f-14 fw-400 lh-20"
                        >
                            Password <span className="text-danger">*</span>
                        </label>
                        <input
                            type="password"
                            className="form-control input-filed"
                            id="password"
                            name="password"
                            value={password}
                            onChange={handleCredentials}
                            placeholder="Your Password"
                        />

                        {errors.password && (
                            <p className="text-danger fs-12">
                                {errors.password}
                            </p>
                        )}
                    </div>
                </div>

                <div className="col-12 px-md-0 position-relative">
                    <label
                        htmlFor="phone"
                        className="form-label c-667085 poppins f-14 fw-400 lh-20"
                    >
                        Mobile number <span className="text-danger">*</span>
                    </label>
                    <div className="input-group input-filed mb-20">
                        <span
                            className="input-group-text border-0 py-0 ps-0 bg-transparent"
                            id="basic-addon1"
                        >
                            <div className="dropdown">
                                <button
                                    className="btn btn-country-num dropdown-toggle"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col px-0">
                                                {countryCode}
                                            </div>
                                        </div>
                                    </div>
                                </button>
                                <div className="dropdown-menu country-dropdown-menu">
                                    <input
                                        className="country-dropdown-input"
                                        type="text"
                                        placeholder="Search for a country..."
                                        value={searchTerm}
                                        onChange={(e) =>
                                            setSearchTerm(e.target.value)
                                        }
                                    />
                                    {filteredOptions.map((_country) => (
                                        <p
                                            key={_country.label}
                                            className="dropdown-item cp"
                                            onClick={() =>
                                                handleCountryCodeClick(_country)
                                            }
                                        >
                                            {_country.flag} {_country.label}{' '}
                                            {_country.code}
                                        </p>
                                    ))}
                                </div>
                            </div>
                        </span>
                        <input
                            name="phone"
                            value={phone}
                            type="text"
                            onChange={handleInputs}
                            className="form-control border-0 py-0 box-shadow-0"
                            placeholder="Your Number"
                            aria-label="mobileNumber"
                            aria-describedby="basic-addon1"
                        />
                    </div>

                    {errors.phone && (
                        <p
                            className="text-danger fs-12"
                            style={{ marginTop: -20 }}
                        >
                            {errors.phone}
                        </p>
                    )}
                </div>

                <div className="col-12 px-md-0 mb-20 position-relative">
                    <label
                        htmlFor="emailInput"
                        className="form-label c-667085 poppins f-14 fw-400 lh-20"
                    >
                        Country <span className="text-danger">*</span>
                    </label>
                    <div className="dropdown">
                        <button
                            className="btn btn-country dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col px-0">{country}</div>
                                    <div className="col-auto px-0">
                                        <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M16.6875 6.0125L10.2875 12.4125L10 12.6875L9.7125 12.4125L3.3125 6.0125L3.8875 5.4375L10 11.55L16.1125 5.4375L16.6875 6.0125Z"
                                                fill="black"
                                            />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </button>
                        <div className="dropdown-menu country-name-dropdown-menu">
                            <input
                                className="country-dropdown-input"
                                type="text"
                                placeholder="Search for a country..."
                                value={searchCountry}
                                onChange={(e) =>
                                    setSearchCountry(e.target.value)
                                }
                            />
                            {filteredCountries.map((_country) => (
                                <span
                                    key={_country.label}
                                    className="dropdown-item cp"
                                    onClick={handleCountryClick}
                                >
                                    {_country.label}
                                </span>
                            ))}
                        </div>
                    </div>
                    {errors.country && (
                        <p className="text-danger fs-12">{errors.country}</p>
                    )}
                </div>

                <div className="col-12 px-md-0 position-relative mb-md-82 mb-4">
                    {/* <div className="form-check mb-82"> */}
                    <div className="form-check-label ps-0 pt-lg-0 pt-4px cp">
                        <div className="d-flex">
                            <input
                                type="checkbox"
                                className="form-check-input check-box-custom cp"
                                name="terms"
                                value={terms}
                                checked={terms}
                                id="flexCheckDefault"
                                onClick={handleInputs}
                            />

                            <span className="">
                                I agree to the{' '}
                                <span
                                    className="c-667085 poppins f-14px fw-medium lh-24"
                                    onClick={() =>
                                        handleTermsClick('/privacy-policy')
                                    }
                                >
                                    Privacy Policy,
                                </span>{' '}
                                <span
                                    className="c-667085 poppins f-14px fw-medium lh-24"
                                    onClick={() => handleTermsClick('/terms')}
                                >
                                    Terms of Services
                                </span>{' '}
                                and{' '}
                                <span
                                    className="c-667085 poppins f-14px fw-medium lh-24"
                                    onClick={() =>
                                        handleTermsClick('/business-terms')
                                    }
                                >
                                    Terms of Business.
                                </span>
                            </span>
                        </div>
                    </div>

                    {errors.terms && (
                        <p className="text-danger fs-12 l-0">{errors.terms}</p>
                    )}
                </div>

                <div className="px-md-0">
                    <button
                        className="btn btn-create-acc mb-20"
                        disabled={isLoading}
                        type="submit"
                    >
                        {isLoading ? <Spin /> : 'Create Account'}
                    </button>
                    <Link
                        to={'/login'}
                        style={{paddingTop:'11px', paddingBottom:'11px'}}
                        className="btn btn-outline border w-100 rounded-pill mb-20"
                        disabled={isLoading}
                    >
                        Login
                    </Link>
                </div>
            </form>
            <div className="col-12 px-md-0 pb-lg-0 pb-5">
                <h6 className="m-0 c-667085 poppins f-14 f-mobile-16 fw-normal lh-20 text-center">
                    <Link
                        to="/register-customer"
                        className="c-334155 poppins f-14 f-mobile-16 lh-20 ms-2"
                    >
                        Sign up/Sign In as a customer{' '}
                    </Link>
                </h6>
            </div>
        </AuthLayout>
    );
};

export default Register;
