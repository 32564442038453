import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader/root';
import { Provider, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
} from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { store } from './redux/store';
// import store from './redux/store';
import Admin from './routes/admin';
import MessageSetup from './routes/admin/messageSetup';
import config from './config/config';
import ProtectedRoute from './components/utilities/protectedRoute';
import 'antd/dist/antd.less';
import Terms from './container/terms';
import Register from './container/auth/register';
import Login from './container/auth/login';
import ForgetPassword from './container/auth/forget-password';
import ResetPassword from './container/auth/reset-password';
import HomePage from './container/home/HomePage';
import BusinessForm from './container/steps';
import Stripe from './container/stripe';
import withAdminLayout from './layout/withAdminLayout';
import { isAuthenticated } from './helpers/functions';
import CreateAccount from './features/auth/create-account';
import RegisterForEveryone from './features/auth/register-for-everyone';
import Appointments from './appointments/appointments';
import NewPagesLayout from './layout/NewPagesLayout';
import AppointmentSearch from './appointments/appointment-search';
import MyFavourites from './features/favourites/MyFavourites';
import ServiceDetails from './features/services/service-details';
import Profilesetting from './appointments/Profilesetting';
import Service from './features/services/Service';
import { AuthRoute } from './routes/AuthRoute';
import LoginForEveryone from './features/auth/login-for-everyone';
import AuthenticatedCustomerRoute from './routes/AuthenticatedCustomerRoute';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LoadScript } from '@react-google-maps/api';
import { GOOGLE_API_KEY } from './helpers/constants';
import BusinessTerms from './container/business-terms';
import PrivacyPolicy from './container/privacy-policy';
import Appointment from './features/services/Appointment';
import Chat from './container/chat/Chat';
import Loading from './components/Loading';
import ScrollToTop from './components/ScrollToTop';
import Setting from './container/setting';
import WriteReview from './container/write-review';

const { theme } = config;

function ProviderConfig() {
    const { rtl, topMenu, darkMode } = useSelector((state) => {
        return {
            darkMode: state.ChangeLayoutMode.data,
            rtl: state.ChangeLayoutMode.rtlData,
            topMenu: state.ChangeLayoutMode.topMenu,
            isLoggedIn: state.auth.login,
        };
    });

    const { userData } = useSelector((state) => state.user);

    return (
        <ConfigProvider>
            <ThemeProvider theme={{ ...theme, rtl, topMenu, darkMode }}>
                <Router basename={process.env.PUBLIC_URL}>
                    <ScrollToTop />
                    <Switch>
                        {/* Authenticated Routes */}
                        <AuthRoute path="/create-account">
                            <CreateAccount />
                        </AuthRoute>
                        <AuthRoute path="/register-customer">
                            <RegisterForEveryone />
                        </AuthRoute>
                        <AuthRoute path="/register">
                            <Register />
                        </AuthRoute>
                        <AuthRoute path="/login">
                            <Login />
                        </AuthRoute>
                        <AuthRoute path="/login-customer">
                            <LoginForEveryone />
                        </AuthRoute>

                        {/* Customer Authenticated */}
                        <AuthenticatedCustomerRoute path="/customer/upcoming-appointments">
                            <NewPagesLayout>
                                <Appointments />
                            </NewPagesLayout>
                        </AuthenticatedCustomerRoute>
                        <AuthenticatedCustomerRoute path="/customer/review/:id">
                            <NewPagesLayout>
                                <WriteReview />
                            </NewPagesLayout>
                        </AuthenticatedCustomerRoute>

                        <AuthenticatedCustomerRoute path="/customer/profile-setting">
                            <NewPagesLayout>
                                <Profilesetting />
                            </NewPagesLayout>
                        </AuthenticatedCustomerRoute>

                        <AuthenticatedCustomerRoute path="/customer/my-favourites">
                            <NewPagesLayout>
                                <MyFavourites />
                            </NewPagesLayout>
                        </AuthenticatedCustomerRoute>

                        <AuthenticatedCustomerRoute
                            exact
                            path="/business-details/:id/services/book-appointment"
                        >
                            <NewPagesLayout>
                                <Appointment />
                            </NewPagesLayout>
                        </AuthenticatedCustomerRoute>

                        <AuthenticatedCustomerRoute path="/customer/chat">
                            <NewPagesLayout>
                                <Chat />
                            </NewPagesLayout>
                        </AuthenticatedCustomerRoute>

                        {/* <AuthenticatedCustomerRoute exact path="/business-details/:id/services/select-time/payment-method">
              <NewPagesLayout>
                <PaymentMethod />
              </NewPagesLayout>
            </AuthenticatedCustomerRoute> */}

                        {/* Public Routes */}
                        <Route
                            exact
                            path="/appointment-search"
                            render={() => (
                                <NewPagesLayout>
                                    <AppointmentSearch />
                                </NewPagesLayout>
                            )}
                        />
                        <Route
                            exact
                            path="/business-details/:id"
                            render={() => (
                                <NewPagesLayout>
                                    <ServiceDetails />
                                </NewPagesLayout>
                            )}
                        />

                        <Route
                            exact
                            path="/business-details/:id/services"
                            render={() => (
                                <NewPagesLayout>
                                    <Service />
                                </NewPagesLayout>
                            )}
                        />

                        <Route
                            path="/business-info/:token"
                            component={BusinessForm}
                        />
                        <Route
                            path="/upload-from-instagram"
                            component={
                                userData && userData.role === 'business'
                                    ? Setting
                                    : BusinessForm
                            }
                        />
                        <Route path="/terms" component={Terms} />
                        <Route
                            path="/business-terms"
                            component={BusinessTerms}
                        />
                        <Route
                            path="/privacy-policy"
                            component={PrivacyPolicy}
                        />
                        <Route
                            path="/forget-password"
                            component={ForgetPassword}
                        />
                        <Route
                            path="/reset-password/:token"
                            component={ResetPassword}
                        />
                        <Route path="/home" component={HomePage} />
                        <Route exact path="/" component={HomePage} />

                        {isAuthenticated() ? (
                            <Route
                                path="/pricing-plan"
                                component={Stripe}
                            />
                        ) : (
                            <Redirect to="/login" />
                        )}

                        {/* Businees Authenticated */}
                        <ProtectedRoute path="/dashboard" component={Admin} />
                        <ProtectedRoute path="/message-setup" component={MessageSetup} />
                    </Switch>
                </Router>
            </ThemeProvider>
        </ConfigProvider>
    );
}

function App() {
    return (
        <Provider store={store}>
            <ToastContainer />
            <LoadScript
                // loading="async"
                loadingElement={<Loading />}
                libraries={['places']}
                googleMapsApiKey={GOOGLE_API_KEY}
            >
                <ProviderConfig />
            </LoadScript>
        </Provider>
    );
}

export default hot(App);
