import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route } from 'react-router-dom';

const MessageSetup = lazy(() => import('../../container/automation/setup/setup'));


const SetupRoute = () => {

  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route path={`/message-setup`} component={MessageSetup} />
      </Suspense>
    </Switch>
  );
}

export default SetupRoute;
