import React, { useRef } from 'react';
import { Checkbox, Dropdown, Menu, Spin, Typography } from 'antd';

import TimeSelector from '../../container/home/TimeSelector';
import ReactDatePicker, { CalendarContainer } from 'react-datepicker';
import LocationSearch from '../../components/locationSearch/LocationSearch';
import { DateSvg, LocationSvg, ServicesSvg } from '../../assets/SearchbarSVGs';
import SVG from '../../container/steps/SVG';
import { SERVICES } from '../../helpers/constants';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import {
    searchServices,
    updateExtraFieldValues,
    updateFilteredValues,
} from '../../redux/services/servicesActions';
import { setSearchData } from '../../redux/users/actionCreator';
import moment from 'moment';

const options = { day: 'numeric', month: 'numeric', year: 'numeric' };
const services = SERVICES.map((item) => item.name);

const SearchBar = () => {
    const servicesDropdownRef = useRef(null);
    const locationRef = useRef(null);
    const dispatch = useDispatch();
    const history = useHistory();
    const { isLoading, filteredValues, extraFields } = useSelector(
        (state) => state.services
    );
    const [isServicesVisible, setIsServicesVisible] = useState(false);
    const [isLocationVisible, setIsLocationVisible] = useState(false);
    const [currentLocation, setCurrentLocation] = useState(false);
    const [permissionError, setPermissionError] = useState(null);
    const [filteredError, setFilteredError] = useState({
        latitude: false,
        longitude: false,
        service: false,
        toTime: false,
        fromTime: false,
        date: false,
    });

    const handleInputVal = (val) => {
        dispatch(updateExtraFieldValues({ ...extraFields, localAddress: val }));
    };

    const handleLocationSelected = ({ lat, lng, address }) => {
        dispatch(
            updateFilteredValues({
                ...filteredValues,
                latitude: lat,
                longitude: lng,
            })
        );
        dispatch(
            updateExtraFieldValues({ ...extraFields, localAddress: address })
        );
        if (address) {
            const dropdowns = document.querySelectorAll('.dropdown-menu');
            dropdowns.forEach((dropdown) => {
                dropdown.classList.remove('show');
            });
        }
    };

    const handleServiceChange = (name) => {
        setIsServicesVisible(true);
        let selectedServices = filteredValues?.service || [];
        const updatedServices = selectedServices.includes(name)
            ? selectedServices.filter((_name) => _name !== name)
            : [...selectedServices, name];
        dispatch(
            updateFilteredValues({
                ...filteredValues,
                service: updatedServices,
            })
        );
    };

    const handleAllDays = () => {
        let newDate = new Date();
        dispatch(
            updateFilteredValues({
                ...filteredValues,
                date: 'All Days',
            })
        );
        dispatch(
            updateExtraFieldValues({
                ...extraFields,
                dateOption: 'All Days',
                localDate: newDate.toISOString(),
            })
        );
    };

    const handleSetToday = () => {
        let newDate = new Date();
        dispatch(
            updateFilteredValues({
                ...filteredValues,
                date: newDate.toLocaleDateString('en-GB', options),
            })
        );
        dispatch(
            updateExtraFieldValues({
                ...extraFields,
                dateOption: 'today',
                localDate: newDate.toISOString(),
            })
        );
    };

    const handleSetTomorrow = () => {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        dispatch(
            updateFilteredValues({
                ...filteredValues,
                date: tomorrow.toLocaleDateString('en-GB', options),
            })
        );
        dispatch(
            updateExtraFieldValues({
                ...extraFields,
                dateOption: 'tomorrow',
                localDate: tomorrow.toISOString(),
            })
        );
    };

    const handleSetWeekdays = () => {
        let newDate = new Date();
        dispatch(
            updateFilteredValues({
                ...filteredValues,
                date: 'weekdays',
            })
        );
        dispatch(
            updateExtraFieldValues({
                ...extraFields,
                dateOption: 'weekdays',
                localDate: newDate.toISOString(),
            })
        );
    };

    const handleSetWeekends = () => {
        let newDate = new Date();
        dispatch(
            updateFilteredValues({
                ...filteredValues,
                date: 'weekends',
            })
        );
        dispatch(
            updateExtraFieldValues({
                ...extraFields,
                dateOption: 'weekends',
                localDate: newDate.toISOString(),
            })
        );
    };

    const handleChoose = () => {
        let newDate = new Date();
        dispatch(
            updateFilteredValues({
                ...filteredValues,
                date: newDate.toLocaleDateString('en-GB', options),
            })
        );
        dispatch(
            updateExtraFieldValues({
                ...extraFields,
                dateOption: 'choose',
                localDate: newDate.toISOString(),
            })
        );
    };
    const handleRange = () => {
        let newDate = new Date();
        dispatch(
            updateFilteredValues({
                ...filteredValues,
                date: 'range',
            })
        );
        dispatch(
            updateExtraFieldValues({
                ...extraFields,
                dateOption: 'range',
                localDate: newDate.toISOString(),
            })
        );
    };

    const handleFromDate = (e) => {
        let newDate = new Date();
        dispatch(
            updateFilteredValues({
                ...filteredValues,
                date: 'range',
                from: e.target.value,
            })
        );
        dispatch(
            updateExtraFieldValues({
                ...extraFields,
                dateOption: 'range',
                localDate: newDate.toISOString(),
            })
        );
    };

    const handleToDate = (e) => {
        let newDate = new Date();
        dispatch(
            updateFilteredValues({
                ...filteredValues,
                date: 'range',
                to: e.target.value,
            })
        );
        dispatch(
            updateExtraFieldValues({
                ...extraFields,
                dateOption: 'range',
                localDate: newDate.toISOString(),
            })
        );
    };

    useEffect(() => {
        const styleElement = document.createElement('style');
        styleElement.innerHTML = `
      .react-datepicker__navigation {
        display: ${extraFields.dateOption === 'choose' ? 'flex' : 'none'};
      }
    `;
        document.head.appendChild(styleElement);

        return () => {
            document.head.removeChild(styleElement);
        };
    }, [extraFields.dateOption]);

    const MyContainer = ({ className, children }) => {
        return (
            <div
                style={{
                    padding: '16px',
                    background: '#216ba5',
                    color: '#fff',
                }}
            >
                <CalendarContainer className={className}>
                    <div className={`container`}>
                        <div className={`row px-3 pt-3 `}>
                            <div
                                className={`col-auto ps-0 pb-3`}
                                onClick={handleAllDays}
                            >
                                <button
                                    className={`btn btn-date ${
                                        extraFields?.dateOption === 'All Days'
                                            ? 'active'
                                            : ''
                                    }`}
                                >
                                    All days
                                </button>
                            </div>
                            <div
                                className={`col-auto ps-0 pb-3`}
                                onClick={handleSetToday}
                            >
                                <button
                                    className={`btn btn-date ${
                                        extraFields?.dateOption === 'today'
                                            ? 'active'
                                            : ''
                                    }`}
                                >
                                    Today
                                </button>
                            </div>
                            <div
                                className={`col-auto px-0 pb-3`}
                                onClick={handleSetTomorrow}
                            >
                                <button
                                    className={`btn btn-date ${
                                        extraFields?.dateOption === 'tomorrow'
                                            ? 'active'
                                            : ''
                                    }`}
                                >
                                    Tomorrow
                                </button>
                            </div>
                            <div
                                className={`col-auto ps-0 pb-3`}
                                onClick={handleSetWeekdays}
                            >
                                <button
                                    className={`btn btn-date ${
                                        extraFields?.dateOption === 'weekdays'
                                            ? 'active'
                                            : ''
                                    }`}
                                >
                                    Weekdays
                                </button>
                            </div>
                            <div
                                className={`col-auto ps-0 pb-3`}
                                onClick={handleSetWeekends}
                            >
                                <button
                                    className={`btn btn-date ${
                                        extraFields?.dateOption === 'weekends'
                                            ? 'active'
                                            : ''
                                    }`}
                                >
                                    Weekends
                                </button>
                            </div>
                            <div
                                className={`col-auto ps-0 pb-3`}
                                onClick={handleChoose}
                            >
                                <button
                                    className={`btn btn-date ${
                                        extraFields?.dateOption === 'choose'
                                            ? 'active'
                                            : ''
                                    }`}
                                >
                                    Choose
                                </button>
                            </div>
                            <div
                                className={`col-auto ps-0 pb-3`}
                                onClick={handleRange}
                            >
                                <button
                                    className={`btn btn-date ${
                                        extraFields?.dateOption === 'range'
                                            ? 'active'
                                            : ''
                                    }`}
                                >
                                    Date Range
                                </button>
                            </div>
                            {extraFields.dateOption === 'range' && (
                                <div className="d-flex gap-3 mb-3">
                                    <div className="d-flex flex-column gap-1">
                                        <label
                                            htmlFor="startDate"
                                            className="text-start"
                                        >
                                            From
                                        </label>
                                        <input
                                            type="date"
                                            name="startDate"
                                            className="p-2 rounded-1"
                                            id="startDate"
                                            value={filteredValues?.from}
                                            onChange={handleFromDate}
                                        />
                                    </div>
                                    <div className="d-flex flex-column gap-1">
                                        <label
                                            htmlFor="endDate"
                                            className="text-start "
                                        >
                                            To
                                        </label>
                                        <input
                                            type="date"
                                            name="endDate"
                                            className="p-2 rounded-1"
                                            id="endDate"
                                            value={filteredValues?.to}
                                            onChange={handleToDate}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div style={{ position: 'relative' }}>{children}</div>
                </CalendarContainer>
            </div>
        );
    };

    const ExampleCustomInput = ({ value, onClick }) => (
        <button
            onClick={onClick}
            style={{
                cursor: 'pointer',
                border: 'none',
                background: 'none',
            }}
            className={`border-0 focus cp p-md-20 px-4 py-8 mb-lg-0 bg-white rounded-pill`}
        >
            <div className={`container-fluid`}>
                <div
                    className={`d-flex align-items-center justify-content-center gap-2`}
                >
                    <div className={`ps-0 pe-md-2 pe-0 wh-sm-18`}>
                        <DateSvg />
                    </div>
                    <div className={`ps-0 position-relative `}>
                        <h6
                            className={`m-0 f-16 fw-normal lh-24 poppins c-0f151d text-capitalize`}
                        >
                            {extraFields && extraFields?.dateOption === 'choose'
                                ? new Date(
                                      extraFields?.localDate
                                  )?.toLocaleDateString('en-GB', options)
                                : extraFields?.dateOption === 'range' &&
                                  filteredValues?.from &&
                                  filteredValues?.to
                                ? moment(filteredValues?.from).format('DD') +
                                  ' To ' +
                                  moment(filteredValues?.to).format('DD/MM/YY')
                                : extraFields.dateOption}
                        </h6>
                        {filteredError.date && (
                            <p className="position-absolute text-danger">
                                Required
                            </p>
                        )}
                    </div>
                </div>
            </div>
        </button>
    );

    const handleDateChange = (date) => {
        dispatch(
            updateFilteredValues({
                ...filteredValues,
                date: date.toLocaleDateString('en-GB', options),
            })
        );
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);

        if (new Date(date).toDateString() === today.toDateString()) {
            dispatch(
                updateExtraFieldValues({
                    ...extraFields,
                    dateOption: 'choose',
                    localDate: date.toISOString(),
                })
            );
        } else if (new Date(date).toDateString() === tomorrow.toDateString()) {
            dispatch(
                updateExtraFieldValues({
                    ...extraFields,
                    dateOption: 'choose',
                    localDate: date.toISOString(),
                })
            );
        } else {
            dispatch(
                updateExtraFieldValues({
                    ...extraFields,
                    dateOption: 'choose',
                    localDate: date.toISOString(),
                })
            );
        }
    };

    const handleSearch = () => {
        if (!filteredValues.latitude && !filteredValues.longitude) {
            getLocation();
        }

        let hasError = false;
        const newError = {};
        let payload = { ...filteredValues };
        if (filteredValues.service.length === 0) {
            payload = { ...filteredValues, service: services };
        }

        Object.entries(payload).forEach(([key, value]) => {
            if (value === '' || (Array.isArray(value) && value.length === 0)) {
                newError[key] = true;
                hasError = true;
            } else {
                newError[key] = false;
            }
        });

        setFilteredError(newError);

        if (hasError) {
            return;
        } else {
            delete payload?.localAddress;
            delete payload?.timeOption;
            dispatch(setSearchData(payload));
            dispatch(
                searchServices(payload, () => {
                    history.push('/appointment-search');
                })
            );
        }
    };
    const getLocation = (val) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    dispatch(
                        updateFilteredValues({
                            ...filteredValues,
                            latitude: position.coords.latitude,
                            longitude: position.coords.longitude,
                        })
                    );
                    dispatch(
                        updateExtraFieldValues({
                            ...extraFields,
                            localAddress: val,
                        })
                    );
                    // setCurrentLocation(true)
                },
                (error) => {
                    console.error('Error obtaining location', error);
                    setCurrentLocation(false);
                    setPermissionError('Allow location permission');
                    setTimeout(() => {
                        setPermissionError('');
                    }, 2000);
                }
            );
        } else {
            console.log('Geolocation is not supported by this browser.');
        }
    };

    const handleCurrentLocation = (e) => {
        const { checked } = e.target;
        setCurrentLocation(checked);
        if (checked) {
            getLocation('');
        }
    };

    useEffect(() => {
        if (filteredValues) {
            const updatedErrors = { ...filteredError };

            Object.keys(filteredValues).forEach((key) => {
                if (filteredError[key]) {
                    if (
                        (Array.isArray(filteredValues[key]) &&
                            filteredValues[key].length > 0) ||
                        (!Array.isArray(filteredValues[key]) &&
                            filteredValues[key] !== '')
                    ) {
                        updatedErrors[key] = false;
                    }
                }
            });

            setFilteredError(updatedErrors);
        }
    }, [filteredValues]);

    useEffect(() => {
        if (!filteredValues.latitude && !filteredValues.longitude)
            getLocation();
    }, [filteredValues.latitude, filteredValues.longitude]);

    const { Text } = Typography;

    const handleOutsideClick = (event) => {
        if (
            servicesDropdownRef.current &&
            !servicesDropdownRef.current.contains(event.target)
        ) {
            setIsServicesVisible(false);
        }
    };

    useEffect(() => {
        const handleLocationOutsideClick = (event) => {
            if (
                locationRef.current &&
                !locationRef.current.contains(event.target)
            ) {
                setIsLocationVisible(false);
            }
        };

        document.addEventListener('click', handleLocationOutsideClick);
        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
            document.removeEventListener('click', handleLocationOutsideClick);
        };
    }, []);

    const menu = (
        <Menu>
            <div className="container" id="dd-id">
                <div className="row justify-content-center align-items-center  gap-2 search-services-dropdown px-4">
                    {SERVICES.map((item, index) => (
                        <Menu.Item
                            key={index}
                            className={`${
                                filteredValues?.service.includes(item.name)
                                    ? 'active_service'
                                    : 'bg-white'
                            } p-2 d-flex justify-content-center align-items-center flex-column rounded-3 border`}
                            onClick={(e) => {
                                e.domEvent.stopPropagation();
                                setIsServicesVisible(true);
                                handleServiceChange(item.name);
                            }}
                        >
                            <div className="d-flex justify-content-center">
                                <SVG icon={item.icon} size={24} />
                            </div>
                            <p className="fs-6 mb-0">{item.name}</p>
                        </Menu.Item>
                    ))}
                </div>
            </div>
        </Menu>
    );

    const locationMenu = (
        <Menu>
            <Menu.Item key="1">
                <div
                    className="container-fluid"
                    onClick={(e) => {
                        e.stopPropagation();
                        setIsLocationVisible(true);
                    }}
                >
                    <div className="row justify-content-center position-relative gap-3">
                        <LocationSearch
                            value={extraFields?.localAddress}
                            setValue={handleInputVal}
                            onPlaceSelected={handleLocationSelected}
                            isOnline={currentLocation}
                        />
                    </div>
                    <div className="d-flex flex-column">
                        <div className="d-flex gap-1 pt-3 px-3">
                            <Checkbox
                                checked={currentLocation}
                                onChange={(e) => {
                                    e.stopPropagation();
                                    handleCurrentLocation(e);
                                }}
                            >
                                Use Current Location
                            </Checkbox>
                        </div>
                        {permissionError && (
                            <span className="fs-10 ps-3 text-danger">
                                {permissionError}
                            </span>
                        )}
                    </div>
                </div>
            </Menu.Item>
        </Menu>
    );

    return (
        <div className="container-fluid">
            <div
                className={`row align-items-center justify-content-center pb-md-110 pb-5`}
            >
                <div
                    className={`col-auto border border-lg-1 border-0 shadow-lg-sm shadow-none text-center custom-bg rounded-pill box-shadow-banner px-0`}
                >
                    <div className={`container-fluid`}>
                        <div
                            className={`row align-items-center justify-content-evenly rounded-pill custom-container-border`}
                        >
                            <div
                                className={`col-lg-auto col-sm-6 col-12 cp mb-lg-0 mb-2`}
                            >
                                <div
                                    className="p-md-20 px-4 py-8 bg-white mx-2 rounded-pill custom-border"
                                    ref={servicesDropdownRef}
                                >
                                    <Dropdown
                                        placement="bottom"
                                        overlay={menu}
                                        trigger={['click']}
                                        visible={isServicesVisible}
                                    >
                                        <button
                                            className="dropdown-toggle border-0 cp p-md-20 px-4 py-8 bg-white"
                                            type="button"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setIsServicesVisible(true);
                                            }}
                                        >
                                            <div className="container-fluid">
                                                <div className="row align-items-center justify-content-center gap-2 ">
                                                    <div className="col-auto ps-0 pe-md-2 pe-0 wh-sm-18">
                                                        <ServicesSvg />
                                                    </div>
                                                    <div className="col-auto ps-0 position-relative">
                                                        <h6 className="m-0 f-16 fw-normal lh-24 poppins c-0f151d">
                                                            {filteredValues &&
                                                            filteredValues
                                                                ?.service
                                                                ?.length === 9
                                                                ? 'All Services'
                                                                : filteredValues
                                                                      ?.service
                                                                      ?.length !==
                                                                  0
                                                                ? `${filteredValues?.service[0]}...`
                                                                : 'Any Service'}
                                                        </h6>
                                                        {filteredError.service && (
                                                            <Text
                                                                type="danger"
                                                                className="position-absolute"
                                                            >
                                                                Required
                                                            </Text>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </button>
                                    </Dropdown>
                                </div>
                            </div>

                            <div className={`col-auto px-0px d-xl-flex d-none`}>
                                <div className={`vl h-2`} />
                            </div>

                            <div
                                className={`col-lg-auto col-sm-6 col-12 cp mb-lg-0 mb-2`}
                            >
                                <div
                                    className="p-md-20 px-4 py-8 bg-white mx-2 rounded-pill custom-border"
                                    ref={locationRef}
                                >
                                    <Dropdown
                                        placement="bottom"
                                        overlay={locationMenu}
                                        trigger={['click']}
                                        visible={isLocationVisible}
                                    >
                                        <button
                                            className="ant-dropdown-link px-1 bg-hover-f3f8fe border-0 cp p-md-20 px-4 py-8 bg-white"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setIsLocationVisible(true);
                                            }}
                                        >
                                            <div className="container-fluid">
                                                <div className="row align-items-center justify-content-center gap-2">
                                                    <div className="col-auto ps-0 pe-md-2 pe-0 wh-sm-18">
                                                        <LocationSvg />
                                                    </div>
                                                    <div className="col-auto ps-0 position-relative">
                                                        <h6 className="m-0 f-16 fw-normal lh-24 poppins c-0f151d">
                                                            {extraFields?.localAddress &&
                                                            filteredValues?.latitude
                                                                ? extraFields
                                                                      ?.localAddress
                                                                      .length >
                                                                  18
                                                                    ? `${extraFields?.localAddress.slice(
                                                                          0,
                                                                          17
                                                                      )}...`
                                                                    : extraFields?.localAddress
                                                                : 'Current Location'}
                                                        </h6>
                                                        {(filteredError.latitude ||
                                                            filteredError.longitude) && (
                                                            <p className="position-absolute text-danger">
                                                                Required
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </button>
                                    </Dropdown>
                                </div>
                                <div
                                    className={`dropdown-menu dropdown-card mt-4`}
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <div className="container-fluid">
                                        <div
                                            className={`row justify-content-center position-relative gap-3`}
                                        >
                                            <LocationSearch
                                                value={
                                                    extraFields?.localAddress
                                                }
                                                setValue={handleInputVal}
                                                onPlaceSelected={
                                                    handleLocationSelected
                                                }
                                                isOnline={currentLocation}
                                            />
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column">
                                        <div className="d-flex gap-1 pt-3 px-3">
                                            <input
                                                type="checkbox"
                                                checked={currentLocation}
                                                onChange={(e) =>
                                                    handleCurrentLocation(e)
                                                }
                                                name=""
                                                id=""
                                            />
                                            <label htmlFor="">
                                                Use Current Location
                                            </label>
                                        </div>
                                        <span className=" fs-10 ps-3 text-danger">
                                            {permissionError && permissionError}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className={`col-auto px-0px d-xl-flex d-none`}>
                                <div className={`vl h-2`} />
                            </div>

                            <div className={`col-lg-auto col-6`}>
                                <div className="p-md-20 py-8 mb-lg-0 bg-white rounded-pill mx-sm-2 mx-0 ms-2 mb-2 custom-border">
                                    <ReactDatePicker
                                        selected={
                                            new Date(extraFields?.localDate) ||
                                            new Date()
                                        }
                                        onSelect={handleDateChange}
                                        calendarContainer={MyContainer}
                                        customInput={<ExampleCustomInput />}
                                        minDate={new Date()}
                                        monthsShown={
                                            extraFields.dateOption === 'choose'
                                                ? true
                                                : false
                                        }
                                        popperPlacement="bottom-end"
                                    />
                                </div>
                            </div>

                            <div className={`col-auto px-0 d-xl-flex d-none`}>
                                <div className={`vl h-2`} />
                            </div>

                            <div className={`col-lg-auto col-6`}>
                                <TimeSelector filteredError={filteredError} />
                            </div>

                            {/* <div className={`col-auto pl-6 pr-0 my-3`}> */}
                            <div
                                className={`col-lg-auto col-sm-5 col mx-sm-0 mx-lg-0 mx-2 mb-sm-0 mb-3`}
                            >
                                <button
                                    className={`btn btn-search w-100`}
                                    type="button"
                                    onClick={handleSearch}
                                    disabled={isLoading}
                                >
                                    {isLoading ? <Spin /> : 'Search'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SearchBar;
