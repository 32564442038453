import Cookies from 'js-cookie';
import actions from './actions';

const {
    AUTH_LOADER,
    LOGIN_BEGIN,
    LOGIN_SUCCESS,
    LOGIN_ERR,
    LOGOUT_BEGIN,
    LOGOUT_SUCCESS,
    LOGOUT_ERR,
    SAVE_USER_ID,
} = actions;

const initState = {
    login: Cookies.get('logedIn'),
    loading: false,
    error: null,
};

/**
 *
 * @todo impure state mutation/explaination
 */
const AuthReducer = (state = initState, action) => {
    const { type, payload, err } = action;
    switch (type) {
        case AUTH_LOADER:
            return {
                ...state,
                loading: payload,
            };

        //LOG IN
        case LOGIN_BEGIN:
            return {
                ...state,
                loading: true,
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                login: payload,
                loading: false,
            };
        case LOGIN_ERR:
            return {
                ...state,
                error: err,
                loading: false,
            };

        //LOG OUT
        case LOGOUT_BEGIN:
            return {
                ...state,
                loading: true,
            };
        case LOGOUT_SUCCESS:
            return {
                ...state,
                // login: payload,
                loading: false,
            };
        case LOGOUT_ERR:
            return {
                ...state,
                error: err,
                loading: false,
            };
        default:
            return state;
    }
};
export default AuthReducer;
