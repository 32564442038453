import React from 'react';
import profileImage from '../../../assets/images/singUpBanner.png';
import Calender from '../../../assets/images/Appointments/Date.svg';
import Time from '../../../assets/images/Appointments/Time.svg';
import moment from 'moment';
import { convertTo12HourFormat } from '../../../helpers/constants';

const AppointmentCard = ({
    appointments,
    title,
    length,
    handleCardClick,
    activeIndex,
}) => {
    return (
        <div className="appointment-container">
            <div className="heading-container">
                <div className={`heading f-30 ${!length && 'mb-4'}`}>
                    {title}
                </div>
                {length && (
                    <p className="description mb-0">
                        You have {length} upcoming{' '}
                        {length === 1 ? 'appointment' : 'appointments'}
                    </p>
                )}
            </div>
            <div className="appointment-card-list">
                {appointments?.map((appointment, index) => (
                    <div
                        key={index}
                        className={`appointment-card ${
                            index === activeIndex ? 'active' : ''
                        }`}
                        onClick={() => handleCardClick(index, appointment)}
                    >
                        <div className="w-full">
                            <div className="d-flex align-items-center gap-3 p-3">
                                <img
                                    src={
                                        appointment?.appointmentService[0]
                                            ?.service?.business?.pictures[0]
                                            ?.photo_path || profileImage
                                    }
                                    alt="image"
                                    className="profile-img"
                                />
                                <div className="d-flex flex-column w-full">
                                    <span className="heading f-16">
                                        {
                                            appointment?.appointmentService[0]
                                                ?.service?.business?.name
                                        }
                                    </span>
                                    <div className="d-flex gap-1 flex-wrap">
                                        {appointment?.appointmentService.map(
                                            (item, index) => {
                                                return (
                                                    <div key={index}>
                                                        <span className="text-bg-secondary rounded p-1 fs-9 fw-bold text-capitalize">
                                                            {
                                                                item?.service
                                                                    ?.name
                                                            }
                                                        </span>
                                                    </div>
                                                );
                                            }
                                        )}
                                    </div>
                                    <div className="mt-1">
                                        <span className="date">
                                            <img
                                                src={Calender}
                                                alt="Calendar"
                                            />
                                            <span className="mx-2 f-14">
                                                {appointment?.date &&
                                                    moment(
                                                        appointment?.date
                                                    ).format('DD/MM/YYYY')}
                                            </span>
                                        </span>
                                    </div>
                                    <div className="d-flex justify-content-between flex-wrap">
                                        <span className="date flex-grow-1 ">
                                            <img src={Time} alt="Time" />
                                            <span className="mx-2 f-14">
                                                {convertTo12HourFormat(
                                                    appointment?.time
                                                )}
                                            </span>
                                        </span>
                                        <div className="price f-16">
                                            £{appointment?.amount}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AppointmentCard;
