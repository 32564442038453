import React from 'react';
import { useHistory } from 'react-router-dom';
import { isAuthenticated } from '../../helpers/functions';
import { useSelector } from 'react-redux';
import {
    businessAverageRating,
    convertTo12HourFormat,
    isBoolean,
    shortToFullDayMap,
} from '../../helpers/constants';
import { useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';

const ContinueCard = ({ selectedServices, businessDetails }) => {
    const history = useHistory();
    const { userData } = useSelector((state) => state.user);
    const [currentDay] = useState(new Date());

    const currentDayShort = currentDay && currentDay.toString().split(' ')[0];
    const fullDayName = shortToFullDayMap[currentDayShort];
    const openingTime = businessDetails?.openingTimes[fullDayName];

    const totalPrice =
        selectedServices &&
        selectedServices.reduce((total, service) => {
            return total + Number(service.price);
        }, 0);

    return (
        <div>
            <div className="p-4 continue-card">
                <div className="d-flex gap-3">
                    <div>
                        <img
                            src={businessDetails?.pictures[0]?.photo_path}
                            className="profile-img"
                        />
                    </div>
                    <div>
                        <p className="m-0 fs-18  c-0f151d poppins fw-600">
                            {businessDetails?.name}
                        </p>
                        <div className="d-flex align-items-center justify-content-between gap-2 flex-wrap">
                            <div className="m-0 fs-16  c-667085 poppins mt-1">
                                {moment(currentDay.toDateString()).format(
                                    'DD/MM/YYYY'
                                )}
                            </div>
                            <div className="m-0 fs-16  c-667085 poppins mt-1">
                                {isBoolean(openingTime?.isOpened)
                                    ? convertTo12HourFormat(openingTime?.from)
                                    : ''}
                            </div>
                        </div>
                        <div className="d-flex justify-content-start align-items-center mt-2">
                            <h6
                                className={`m-0 c-0f151d poppins f-12 fw-normal lh-16`}
                            >
                                {businessAverageRating(
                                    businessDetails?.reviews || []
                                )}
                            </h6>
                            <svg
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="mx-1"
                            >
                                <g clipPath="url(#clip0_252_296)">
                                    <path
                                        d="M2.44884 11.6623C2.40084 11.6623 2.35284 11.6383 2.30484 11.6143C2.23284 11.5663 2.18484 11.4463 2.20884 11.3503L3.26484 7.41428L0.0968438 4.84628C0.000843818 4.79828 -0.0231562 4.67828 0.000843815 4.58228C0.0248438 4.48628 0.120844 4.41428 0.216844 4.41428L4.29684 4.19828L5.76084 0.382281C5.80884 0.310281 5.90484 0.238281 6.00084 0.238281C6.09684 0.238281 6.19284 0.310281 6.21684 0.382281L7.68084 4.19828L11.7608 4.41428C11.8568 4.41428 11.9528 4.48628 11.9768 4.58228C12.0008 4.67828 11.9768 4.77428 11.9048 4.84628L8.73684 7.41428L9.79284 11.3503C9.81684 11.4463 9.79284 11.5423 9.69684 11.6143C9.62484 11.6623 9.50484 11.6863 9.43284 11.6143L6.00084 9.40628L2.56884 11.6143C2.52084 11.6623 2.49684 11.6623 2.44884 11.6623Z"
                                        fill="#334155"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_252_296">
                                        <rect
                                            width="12"
                                            height="12"
                                            fill="white"
                                        />
                                    </clipPath>
                                </defs>
                            </svg>
                            <h6
                                className={`m-0  c-0f151d poppins f-12 fw-normal lh-16`}
                            >
                                ({businessDetails?.reviews.length || 0})
                            </h6>
                        </div>
                    </div>
                </div>
                <p className="f-18 poppins mt-3 fw-600 ">Overview</p>
                {selectedServices && selectedServices?.length > 0 ? (
                    <div className="mt-2">
                        {selectedServices?.map((item, index) => (
                            <div
                                className="d-flex justify-content-between"
                                key={index}
                            >
                                <div>
                                    <div className="f-18 poppins fw-600 ">
                                        {item?.name}
                                    </div>
                                    <div className="f-16 c-667085 poppins">
                                        {item?.duration}
                                    </div>
                                </div>
                                <div className="fw-600 f-16 poppins">
                                    £{item?.price}
                                </div>
                            </div>
                        ))}
                        <hr />
                        <div className="d-flex justify-content-between">
                            <span className="fw-600 f-16 poppins">Total</span>
                            <span className="fw-600 f-16 poppins">
                                £{totalPrice}
                            </span>
                        </div>
                    </div>
                ) : (
                    <div className="mt-2">
                        <p>
                            Please choose the services you prefer and click the
                            "continue" button to proceed to the next step.
                        </p>
                    </div>
                )}
                <button
                    type="button"
                    onClick={() => {
                        if (
                            isAuthenticated() &&
                            userData?.role === 'business'
                        ) {
                            toast.error(
                                'To book an appointment, you need to create a customer account first.'
                            );
                        } else if (
                            isAuthenticated() &&
                            userData?.role === 'customer' &&
                            userData?.status === 'inactive'
                        ) {
                            toast.error(
                                'Your account is temporarily deactivated. Please activate your account from the profile settings before booking an appointment.'
                            );
                        } else if (
                            isAuthenticated() &&
                            userData?.role === 'customer'
                        ) {
                            history.push(
                                `/business-details/${businessDetails?.id}/services/book-appointment`
                            );
                        } else {
                            history.push(`/login-customer`, {
                                fallback: `/business-details/${businessDetails?.id}/services/book-appointment`,
                            });
                        }
                    }}
                    disabled={selectedServices && selectedServices?.length < 1}
                    className="f-16 poppins text-white booking-btn w-100 mt-4 cp text-decoration-none d-block text-center"
                >
                    Continue
                </button>
            </div>
        </div>
    );
};

export default ContinueCard;
