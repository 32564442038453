import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Dashboard from './dashboard';
import Customer from './customer';
import Service from './service';
import withAdminLayout from '../../layout/withAdminLayout';
import Chat from '../../container/chat/Chat';
import BusinessReviewsLayout from '../../container/businessReviews/BusinessReviewsLayout';
const Setting = lazy(() => import('../../container/setting'));
const Calendars = lazy(() => import('../../container/Calendar'));
const Calendar = lazy(() => import('../../container/calendar/Calendar'));
const AutomatedMessages = lazy(() =>
    import('../../container/automation/messages/messages')
);
const Stripe = lazy(() => import('../../container/stripe'));

const Admin = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Suspense
                fallback={
                    <div className="spin">
                        <Spin />
                    </div>
                }
            >
                <Route path={path} component={Dashboard} />
                <Route path={`${path}/customer-history`} component={Customer} />
                {/*<Route path={`${path}/pricing-plan`} component={withAdminLayout(Stripe)} />*/}
                {/*<Route path="/dashboard/pricing-plan" component={withAdminLayout(Stripe)} />*/}
                <Route path={`${path}/calendar`} component={Calendars} />
                <Route path={`${path}/app/calendar`} component={Calendar} />
                <Route path={`${path}/services`} component={Service} />
                <Route path={`${path}/setting`} component={Setting} />
                <Route path={`${path}/chat`} component={Chat} />
                <Route path={`${path}/business-reviews`} component={BusinessReviewsLayout} />
                <Route path={`${path}/automated-messages`} component={AutomatedMessages} />
            
            </Suspense>
        </Switch>
    );
};

export default withAdminLayout(Admin);
