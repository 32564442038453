import React from 'react';
import { Link } from 'react-router-dom';
import { GrayStar } from './svgs';
import BusinessCards from '../../components/BusinessCards';

const Recomend = ({ data }) => {
    return (
        <div className={`container`}>
            <div
                className={`row justify-content-center pb-md-100 pt-md-0 pb-5`}
            >
                <div className={`col pb-md-40 pb-3 px-16`}>
                    <h1
                        className={`poly f-36 fw-normal lh-48 c-334155 mb-16 text-md-start text-center`}
                    >
                        We Recommend
                    </h1>
                    <h3
                        className={`poppins f-18 fw-normal lh-32 c-667085 text-md-start text-center`}
                    >
                        Our experts tried and they liked these services
                    </h3>
                </div>

                <div className={`col-12 px-md-0`}>
                    <div className={`container-fluid`}>
                        <div className={`row`}>
                            {data.map((item, index) => {
                                return (
                                    <div key={index} className='col-12 col-lg-4 px-0'>
                                        <BusinessCards item={item} />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Recomend;
