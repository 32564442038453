import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete } from '@react-google-maps/api';

const LocationSearch = ({ value, setValue, onPlaceSelected, isOnline }) => {
    const [data, setData] = useState();

    const handlePlaceSelected = () => {
        const place = data.getPlace();
        const { geometry, formatted_address } = place;
        const { lat, lng } = geometry.location;
        setValue(formatted_address);
        onPlaceSelected({ lat: lat(), lng: lng(), address: formatted_address });
    };

    const onValueChange = (e) => {
        e.stopPropagation();
        let value = e.target.value;
        setValue(value);
        if (!value) {
            onPlaceSelected({ lat: '', lng: '', address: value });
        }
    };

    return (
        <Autocomplete
            onLoad={(autocomplete) => {
                setData(autocomplete);
            }}
            onPlaceChanged={handlePlaceSelected}
        >
            <input
                type="text"
                value={value}
                onChange={(e) => {
                    e.stopPropagation();
                    onValueChange(e);
                }}
                className="form-control input-filed"
                placeholder="Search for a place"
                disabled={isOnline}
                required
            />
        </Autocomplete>
    );
};

LocationSearch.propTypes = {
    value: PropTypes.string.isRequired,
    setValue: PropTypes.func.isRequired,
    onPlaceSelected: PropTypes.func.isRequired,
};

export default LocationSearch;
