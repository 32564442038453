import { toast } from 'react-toastify';
import { axiosInstance } from '../../repository/Repository';
import {
    ADD_PAYMENT_ERROR,
    ADD_PAYMENT_START,
    ADD_PAYMENT_SUCCESS,
    REFERRAL_ID,
    UPDATE_CUSTOMER_ERROR,
    UPDATE_CUSTOMER_PENDING,
    UPDATE_CUSTOMER_SUCCESS,
} from './types';

export const setUser = (userData) => ({
    type: 'SET_USER',
    payload: userData,
});

export const setSearchData = (searchData) => ({
    type: 'SET_SEARCH_DATA',
    payload: searchData,
});

export const handleTempData = (data) => ({
    type: 'TEMP_DATA',
    payload: data,
});

export const saveReferralId = (id) => ({
    type: REFERRAL_ID,
    payload: id,
});

export const getCustomerDetails = () => async (dispatch) => {
    try {
        const response = await axiosInstance.get('/user/customer');
        dispatch(setUser(response?.data));
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const updateCustomerPersonalInfo =
    (id, data, message, onSuccess = () => {}) =>
    async (dispatch) => {
        dispatch({ type: UPDATE_CUSTOMER_PENDING });
        try {
            await axiosInstance.put(`/user/update/${id}`, data);
            await dispatch(getCustomerDetails());
            dispatch({ type: UPDATE_CUSTOMER_SUCCESS });
            toast.success(message);
            onSuccess();
        } catch (error) {
            dispatch({ type: UPDATE_CUSTOMER_ERROR });
            throw error;
        }
    };

export const addPaymentMethod =
    (data, onSuccess = () => {}, onError = () => {}) =>
    async (dispatch) => {
        dispatch({ type: ADD_PAYMENT_START });
        try {
            await axiosInstance.post(`/user-payment/add`, data);
            await dispatch(getCustomerDetails());
            dispatch({ type: ADD_PAYMENT_SUCCESS });
            toast.success('Card details added successfully');
            onSuccess();
        } catch (error) {
            console.log(error);
            toast.error(
                error?.data?.message ||
                    'There was an error, Please try again later!'
            );
            onError();
            dispatch({ type: ADD_PAYMENT_ERROR });
            throw error;
        }
    };

export const deletePaymentMethod =
    (id, onSuccess = () => {}) =>
    async (dispatch) => {
        dispatch({ type: ADD_PAYMENT_START });
        try {
            await axiosInstance.delete(`/user-payment/delete/${id}`);
            await dispatch(getCustomerDetails());
            dispatch({ type: ADD_PAYMENT_SUCCESS });
            toast.success('Card details deleted successfully');
            onSuccess();
        } catch (error) {
            toast.error(error?.data?.message);
            dispatch({ type: ADD_PAYMENT_ERROR });
            throw error;
        }
    };

export const updateUserStatus =
    (status, onSuccess = () => {}) =>
    async (dispatch) => {
        dispatch({ type: ADD_PAYMENT_START });
        try {
            const response = await axiosInstance.put(`/user/customer/status`, {
                status,
            });
            await dispatch(getCustomerDetails());
            dispatch({ type: ADD_PAYMENT_SUCCESS });
            toast.success(response.data?.message);
            onSuccess();
        } catch (error) {
            toast.error(error?.data?.message);
            dispatch({ type: ADD_PAYMENT_ERROR });
            throw error;
        }
    };
