import React from "react";
import AuthLayout from "./auth-layout";
import Line from "../../assets/images/Line.svg";
import "./create-account.css";
import SignUpBanner from "../../assets/images/singUpBanner.png";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import AuthBanner from "./auth-banner";

const CreateAccount = () => {
  return (
    <div className={`container-fluid`}>
      <div className={`row justify-content-center`}>
        <div className={`col-md-6 col-12 px-0 d-lg-block d-none`}>
          <img src={SignUpBanner} alt="Banner" class="w-100" />{" "}
        </div>
        <div className="col-md-6 auth-banner-right ">
          <div className="container-fluid">
            <div className={`row create-account`}>
              <div className={`col-12 px-0 `}>
                <h5
                  className={`c-334155 poly f-36 fw-400 lh-48 m-0 text-center`}
                >
                  Sign up/log in
                </h5>
                <h6
                  className={`c-667085 poppins f-14 fw-400 lh-20 m-0 text-center mt-1`}
                >
                  please fill the information below
                </h6>
              </div>
              <div
                className={`col-12 px-md-0 position-relative d-flex justify-content-center`}
              >
                <Link
                  to={"/register-customer"}
                  className="account-container text-decoration-none"
                >
                  <div>
                    <h5
                      className={`form-label c-667085 poppins f-14 fw-500 lh-20`}
                    >
                      For everyone
                    </h5>
                    <h6
                      className={`c-667085 poppins f-14 fw-400 lh-20 m-0 text-center`}
                    >
                      Book salons and spas near you
                    </h6>
                  </div>
                  <div>
                    <div>
                      <img src={Line} role="button" />
                    </div>
                  </div>
                </Link>
              </div>
              <div
                className={`col-12 px-md-0 position-relative d-flex justify-content-center`}
              >
                <Link
                  to={"/register"}
                  className="account-container text-decoration-none"
                >
                  <div>
                    <h5
                      className={`form-label c-667085 poppins f-14 fw-500 lh-20`}
                    >
                      For business
                    </h5>
                    <h6
                      className={`c-667085 poppins f-14 fw-400 lh-20 m-0 text-center`}
                    >
                      Manage and grow your business
                    </h6>
                  </div>
                  <div>
                    <div>
                      <img src={Line} role="button" />
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAccount;
