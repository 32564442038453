import React from 'react';
import Header from '../../layout/Header';
import Footer from '../../layout/Footer';
import '../../';
import './home-css.css';
import './calender.css';
import SearchBar from '../../features/services/search-bar';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import {
    fetchLatestBusinesses,
    fetchRecomendedBusinesses,
    resetFilterValues,
} from '../../redux/services/servicesActions';
import { LeftArrow, RightArrow, Star } from './svgs';
import CustomerFeedback from './CustomerFeedback';
import TisumeForBusiness from './TisumeForBusiness';
import CheckoutNewPlaces from './CheckoutNewPlaces';
import Recomend from './Recomend';
import TisumeBenifits from './TisumeBenifits';
import ExperienceBeautyServices from './ExperienceBeautyServices';
import TestimonialsSlider from './TestimonialsSlider';
import { useRef } from 'react';
import { useSelector } from 'react-redux';

const HomePage = () => {
    const dispatch = useDispatch();
    const sliderRef = useRef(null);

    const { latestBusinesses, recomendedBusinesses } = useSelector(
        (state) => state.services
    );

    const handlePrev = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPrev();
        }
    };

    const handleNext = () => {
        if (sliderRef.current) {
            sliderRef.current.slickNext();
        }
    };

    useEffect(() => {
        dispatch(resetFilterValues());
        dispatch(fetchLatestBusinesses());
        dispatch(fetchRecomendedBusinesses());
    }, []);

    return (
        <div className={`landing-page-body`}>
            <div className="header-bg">
                <Header />

                <section>
                    <div className={`container-fluid pt-md-120 pb-md-200 py-5`}>
                        <div
                            className={`row align-items-center justify-content-center`}
                        >
                            <div className={`col-md-12 col-auto px-md-0`}>
                                <h1
                                    className={`mb-md-24 f-100 fw-normal lh-120 poly c-gold text-center`}
                                >
                                    Book The Look
                                </h1>
                            </div>
                            <div className={`col-md-7 col-auto px-md-0 px-5`}>
                                <h1
                                    className={`mb-md-50 mb-md-4 mb-5 f-24 fw-normal lh-36 poppins text-white-80 text-center max-w-md-976 mx-auto`}
                                >
                                    Beauty on your terms
                                </h1>
                            </div>
                        </div>

                        <SearchBar />

                        <div
                            className={`row align-items-center justify-content-center`}
                        >
                            <div className={`col-auto px-md-0 text-center`}>
                                {/* <a
                                    href="#"
                                    className={`c-gold poppins f-18 fw-normal lh-24 text-decoration-none white-space-no-wrap`}
                                >
                                    Show All Services
                                    <div
                                        className={`border-bottom-2-gold pt-md-12`}
                                    />
                                </a> */}
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {Array.isArray(latestBusinesses) && latestBusinesses.length > 0 && (
                <section>
                    <CheckoutNewPlaces data={latestBusinesses.slice(0, 3)} />
                </section>
            )}

            {Array.isArray(recomendedBusinesses) &&
                recomendedBusinesses?.length > 0 && (
                    <section>
                        <Recomend data={recomendedBusinesses.slice(0, 3)} />
                    </section>
                )}

            <section>
                <TisumeBenifits />
            </section>

            {/* <section className="container-fluid">
                <div
                    className={`d-flex flex-lg-row flex-column pt-md-100 pt-5 pl-md-120 pe-md-0 px-0px`}
                >
                    <div className="testimonial-left-section mb-2">
                        <div
                            className={`position-relative box-sizing-content-box`}
                        >
                            <h3
                                className={`m-0 c-334155 poly f-36 fw-normal lh-40 pb-25 px-md-0 px-5 text-md-start text-center`}
                            >
                                What people say about us
                            </h3>
                            <h6
                                className={`m-0 c-667085 poppins f-18 fw-normal lh-normal pb-65 px-md-0 px-5 text-md-start text-center`}
                            >
                                we are proud of what our reviewer's have to say
                            </h6>

                            <div
                                className={`container-fluid d-lg-block d-none`}
                            >
                                <div
                                    className={`row justify-content-md-start justify-content-center`}
                                >
                                    <div className="col-auto px-0px">
                                        <button
                                            className="btn-left-arrow pr-20"
                                            type="button"
                                            onClick={handlePrev}
                                        >
                                            <LeftArrow />
                                        </button>
                                    </div>
                                    <div className="col-auto px-0px">
                                        <button
                                            className="btn-right-arrow"
                                            type="button"
                                            onClick={handleNext}
                                        >
                                            <RightArrow />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="testimonial-right-section">
                        <TestimonialsSlider sliderRef={sliderRef} />
                    </div>
                    <div className={`container-fluid d-lg-none d-block mt-4`}>
                        <div className={`row justify-content-center`}>
                            <div className="col-auto px-0px">
                                <button
                                    className="btn-left-arrow pr-20"
                                    type="button"
                                    onClick={handlePrev}
                                >
                                    <LeftArrow />
                                </button>
                            </div>
                            <div className="col-auto px-0px">
                                <button
                                    className="btn-right-arrow"
                                    type="button"
                                    onClick={handleNext}
                                >
                                    <RightArrow />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            {/* <section>
                <ExperienceBeautyServices />
            </section> */}

            <section className={`pb-md-0 pb-100`}>
                <TisumeForBusiness />
            </section>

            <Footer />
        </div>
    );
};

export default HomePage;
