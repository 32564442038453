import React, { useState } from 'react';
import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    useElements,
    useStripe,
} from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { addPaymentMethod } from '../../redux/users/actionCreator';
import { useSelector } from 'react-redux';
import { Spin } from 'antd';

const StripeCard = ({ handleClose }) => {
    const {userData, isCardLoading } = useSelector((state) => state.user);
    const [loading, setLoading] = useState(false);

    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();

    const [cardHolderName, setCardHolderName] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (!stripe || !elements) {
            return;
        }

        const cardNumberElement = elements.getElement(CardNumberElement);
        const cardExpiryElement = elements.getElement(CardExpiryElement);
        const cardCvcElement = elements.getElement(CardCvcElement);

        if (
            !cardNumberElement._complete ||
            !cardExpiryElement._complete ||
            !cardCvcElement._complete ||
            !cardHolderName
        ) {
            setLoading(false);
            toast.error('Please fill in all the required fields.');
            return;
        }

        try {
            const { token, error } = await stripe.createToken(
                cardNumberElement,
                {
                    name: cardHolderName,
                }
            );
            const { paymentMethod, error: paymentMethodError } =
                await stripe.createPaymentMethod({
                    type: 'card',
                    card: cardNumberElement,
                    billing_details: {
                        name: cardHolderName,
                    },
                });

            if (error || paymentMethodError) {
                setLoading(false);
                toast.error(
                    error?.message || 'An error occurred. Please try again.'
                );
            } else {
                dispatch(
                    addPaymentMethod(
                        { token: token.id, paymentMethodId: paymentMethod.id, email:userData?.email },
                        () => {
                            handleClose();
                            setLoading(false);
                            setCardHolderName('');
                            cardNumberElement.clear();
                            cardExpiryElement.clear();
                            cardCvcElement.clear();
                        },
                        () => setLoading(false)
                    )
                );
            }
        } catch (error) {
            setLoading(false);
            toast.error('An error occurred. Please try again.');
            console.error(error);
        }
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div className="mb-2">
                    <label className="poppins c-667085 f-14 lh-20">
                        Name on card
                        <span className="text-danger ">*</span>
                    </label>
                </div>
                <input
                    type="text"
                    value={cardHolderName}
                    onChange={(e) => setCardHolderName(e.target.value)}
                    placeholder="Alena Franci"
                    className="w-100 border border-color-c-e1e6ef poppins rounded-5 py-3 px-3 lh-20 f-16 c-0f151d"
                />

                <div className="mb-2 mt-2">
                    <label className="poppins c-667085 f-14 lh-20">
                        Card Number
                    </label>
                </div>
                <div className="position-relative">
                    <CardNumberElement
                        options={{ placeholder: '4242 4242 4242 4242' }}
                        className="w-100 border border-color-c-e1e6ef poppins rounded-5 py-3  lh-24 f-16 c-0f151d px-3"
                    />
                    <div className="position-absolute  end-0 top-50 translate-middle-y  text-black me-4">
                        <svg
                            width="18"
                            height="14"
                            viewBox="0 0 18 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M16.5 0.75H1.5C1.16848 0.75 0.850537 0.881696 0.616116 1.11612C0.381696 1.35054 0.25 1.66848 0.25 2V12C0.25 12.3315 0.381696 12.6495 0.616116 12.8839C0.850537 13.1183 1.16848 13.25 1.5 13.25H16.5C16.8315 13.25 17.1495 13.1183 17.3839 12.8839C17.6183 12.6495 17.75 12.3315 17.75 12V2C17.75 1.66848 17.6183 1.35054 17.3839 1.11612C17.1495 0.881696 16.8315 0.75 16.5 0.75ZM16.5 2V3.875H1.5V2H16.5ZM16.5 12H1.5V5.125H16.5V12ZM15.25 10.125C15.25 10.2908 15.1842 10.4497 15.0669 10.5669C14.9497 10.6842 14.7908 10.75 14.625 10.75H12.125C11.9592 10.75 11.8003 10.6842 11.6831 10.5669C11.5658 10.4497 11.5 10.2908 11.5 10.125C11.5 9.95924 11.5658 9.80027 11.6831 9.68306C11.8003 9.56585 11.9592 9.5 12.125 9.5H14.625C14.7908 9.5 14.9497 9.56585 15.0669 9.68306C15.1842 9.80027 15.25 9.95924 15.25 10.125ZM10.25 10.125C10.25 10.2908 10.1842 10.4497 10.0669 10.5669C9.94973 10.6842 9.79076 10.75 9.625 10.75H8.375C8.20924 10.75 8.05027 10.6842 7.93306 10.5669C7.81585 10.4497 7.75 10.2908 7.75 10.125C7.75 9.95924 7.81585 9.80027 7.93306 9.68306C8.05027 9.56585 8.20924 9.5 8.375 9.5H9.625C9.79076 9.5 9.94973 9.56585 10.0669 9.68306C10.1842 9.80027 10.25 9.95924 10.25 10.125Z"
                                fill="currentColor"
                            />
                        </svg>
                    </div>
                </div>
                <div className="poppins d-flex gap-5 justify-content-center align-items-center mt-2">
                    <div className="w-100">
                        <div className="mb-2 mt-2">
                            <label className="c-667085 f-14 lh-20">
                                Expiry date
                            </label>
                        </div>

                        <CardExpiryElement className="border border-color-c-e1e6ef py-3 px-3 rounded-5 w-100" />
                    </div>
                    <div className="w-100">
                        <div className="mb-2 mt-2">
                            <label className="c-667085 f-14 lh-20">
                                Security code
                            </label>
                        </div>

                        <CardCvcElement
                            options={{ placeholder: 'CVV' }}
                            className="form-control form-control-solid inputNumber border border-color-c-e1e6ef py-3 px-3 rounded-5 w-100"
                        />
                    </div>
                </div>

                <div className="d-flex gap-2 mt-4 justify-content-end">
                    <button
                        type="button"
                        onClick={handleClose}
                        className="btn btn-outline "
                        disabled={isCardLoading || loading}
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        disabled={isCardLoading || loading}
                        className="btn btn-success px-3"
                    >
                        {isCardLoading || loading ? <Spin /> : 'Submit'}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default StripeCard;
