import Cookies from 'js-cookie';
import actions from './actions';
import { axiosInstance } from '../../repository/Repository';
import { toast } from 'react-toastify';
import {
    handleTempData,
    saveReferralId,
    setUser,
} from '../users/actionCreator';
import { setSession } from '../../helpers/functions';

const {
    AUTH_LOADER,
    loginBegin,
    loginSuccess,
    loginErr,
    logoutBegin,
    logoutSuccess,
    logoutErr,
} = actions;

const login = () => {
    return async (dispatch) => {
        try {
            dispatch(loginBegin());
            setTimeout(() => {
                Cookies.set('logedIn', true);
                return dispatch(loginSuccess(true));
            }, 1000);
        } catch (err) {
            dispatch(loginErr(err));
        }
    };
};

const logOut = () => {
    return async (dispatch) => {
        try {
            dispatch(logoutBegin());
            Cookies.remove('logedIn');
            dispatch(logoutSuccess(null));
        } catch (err) {
            dispatch(logoutErr(err));
        }
    };
};

export const customerSignUp = (payload, referral, history) => {
    return async (dispatch, getState) => {
        dispatch({ type: AUTH_LOADER, payload: true });
        try {
            // const { referralId } = getState().user;
            const response = await axiosInstance.post(
                `/customer-auth/signup${referral ? `?referral=${referral}` : ''}`,
                payload
            );

            dispatch(
                SignIn(
                    { email: payload?.email, password: payload?.password },
                    history
                )
            );
            dispatch({ type: AUTH_LOADER, payload: false });
            toast.success('Registered successfully');
            console.log('SignUp successful:', response.data);
            return response.data;
        } catch (error) {
            dispatch({ type: AUTH_LOADER, payload: false });
            console.log('SignUp failed:', error);
            toast.error(
                error?.data?.message[0]?.message || error?.data?.message
            );
            // throw error;
        }
    };
};

export const SignIn = (
    payload,
    history,
    onSuccess = (res) => { },
    onError = () => { }
) => {
    return async (dispatch) => {
        dispatch({ type: AUTH_LOADER, payload: true });
        try {
            const response = await axiosInstance.post('/auth/login', payload);
            dispatch({ type: AUTH_LOADER, payload: false });
            dispatch(saveReferralId(''));
            let res = response?.data;
            if (!res.user?.business && res?.user?.role === 'business') {
                let additionalData = { ...payload, user_id: res?.user?.id };
                dispatch(handleTempData(additionalData));
                history.push(
                    `/business-info/${res.user.reset_password_code}`
                    // additionalData
                );
            } else {
                setSession(res);
                dispatch(setUser(res.user));
                if (res?.user?.role === 'customer') {
                    if (
                        history?.location &&
                        history?.location.state?.fallback
                    ) {
                        history.push(history?.location.state?.fallback);
                    } else {
                        history.push('/customer/upcoming-appointments');
                    }
                } else if (
                    res?.user?.role === 'business' &&
                    res.user?.subscription === null
                ) {
                    history.push('/pricing-plan');
                } else {
                    history.push('/dashboard');
                }
            }

            onSuccess();
            return response.data;
        } catch (error) {
            onError(error);
            dispatch({ type: AUTH_LOADER, payload: false });
            console.log('SignIn failed:', error);
            toast.error(error?.data?.message);
            // throw error;
        }
    };
};

export const businessSignUp = (payload, referral, onSuccess = (res) => { }) => {
    return async (dispatch, getState) => {
        dispatch({ type: AUTH_LOADER, payload: true });
        try {
            // const { referralId } = getState().user;
            const response = await axiosInstance.post(
                `user/add${referral ? `?referral=${referral}` : ''}`,
                payload
            );
            dispatch({ type: AUTH_LOADER, payload: false });
            onSuccess(response.data);
            return response.data;
        } catch (error) {
            dispatch({ type: AUTH_LOADER, payload: false });
            console.log('SignUp failed:', error);
            toast.error(
                error?.data?.message[0]?.message || error?.data?.message
            );
            // throw error;
        }
    };
};

export const submitBusinessDetails = (
    payload,
    history,
    auth,
    onSuccess = (res) => { }
) => {
    return async (dispatch) => {
        dispatch({ type: AUTH_LOADER, payload: true });
        try {
            const response = await axiosInstance.post(`business/add`, payload);
            if (auth) {
                await dispatch(SignIn(auth, history));
            }
            onSuccess(response.data);
            history.push('/login');
            toast.success('Registered successfully');
            dispatch({ type: AUTH_LOADER, payload: false });
            return response.data;
        } catch (error) {
            dispatch({ type: AUTH_LOADER, payload: false });
            console.log('Details adding Error:', error);
            toast.error(
                error?.data?.message[0]?.message || error?.data?.message
            );
            throw error;
        }
    };
};

export const handleLogout = () => async (dispatch) => {
    dispatch({ type: 'LOGOUT_SUCCESS' });
};

export { login, logOut };
