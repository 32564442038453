import { getCountryDataList } from 'countries-list';
import moment from 'moment';
import Flag from 'react-world-flags';
import {
    MasterCardSvg,
    VisaCardSvg,
} from '../appointments/profile-settings/svgs';

export const GOOGLE_API_KEY = 'AIzaSyB8bPA-RLpzk5Nsc9gChB9X3Xyzo3hDW-g';

export const IG_APP_ID = '2459785107564082';
export const IG_APP_SECRET = '41937090420eb96f4e998fd3e6828d84';
export const BASEURL =
    window.location.protocol + '//' + window.location.hostname;
// export const BASEURL = 'https://localhost:3000/';

// Live Keys
export const MONTHLY_PRICE = 'price_1PhmESI9A8h3LxM1uxQDq9Pg';
export const ANNUAL_PRICE = 'price_1PhmAsI9A8h3LxM1txdjonaW';

// Test keys
// export const MONTHLY_PRICE = 'price_1OwfFQI9A8h3LxM11R9OM4Uc';
// export const ANNUAL_PRICE = 'price_1OwfFaI9A8h3LxM1eQUvgfVq';

export const SERVICES = [
    { name: 'Nails', icon: 'nail' },
    // { name: 'Hair & Styling', icon: 'hair' },
    { name: 'Makeup', icon: 'makeup' },
    { name: 'Eyebrows and Lashes', icon: 'eyebrow' },
    // { name: 'Facial', icon: 'facial' },
    // { name: 'Hair Removal', icon: 'removal' },
    // { name: 'Spray Tanning', icon: 'spray' },
    { name: 'Massage', icon: 'massage' },
    // { name: 'Fitness', icon: 'fitness' },
    { name: 'Barber', icon: 'barber' },
    { name: 'Wigs', icon: 'wigs' },
    { name: 'Braids', icon: 'braids' },
    { name: 'Aesthetics', icon: 'aesthetics' },
];

export const numericRegex = /^[0-9]*$/;
export const alphabetsRegex = /^[a-zA-Z ]*$/;
export const urlRegex =
    /^https?:\/\/(?:www\.)?[\w-]+\.[\w.-]+(?:\/[\w\/.-]*)?$/;

export const validateEmail = (email, setErrors) => {
    if (email === '') {
        setErrors((prev) => ({
            ...prev,
            email: 'Email is required',
        }));
        return false;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
        setErrors((prev) => ({
            ...prev,
            email: 'Email must be a valid email address',
        }));
        return false;
    }
    return true;
};

export const validatePassword = (password, setErrors) => {
    if (password === '') {
        setErrors((prev) => ({
            ...prev,
            password: 'Password is required',
        }));
        return false;
    } else if (!/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>?~]/.test(password)) {
        setErrors((prev) => ({
            ...prev,
            password: 'Password must contain atleast one special character',
        }));
        return false;
    } else if (!/[A-Z]/.test(password)) {
        setErrors((prev) => ({
            ...prev,
            password: 'Password must contain at least one uppercase letter.',
        }));
        return false;
    } else if (!/[a-z]/.test(password)) {
        setErrors((prev) => ({
            ...prev,
            password: 'Password must contain at least one lowercase letter.',
        }));
        return false;
    } else if (password.length < 8) {
        setErrors((prev) => ({
            ...prev,
            password: 'Password should be 8 characters long',
        }));
        return false;
    }
    return true;
};

export const convertTo12HourFormat = (time) => {
    const [hour, minute] = time.split(':');
    let suffix = 'AM';
    let hour12 = parseInt(hour);
    if (hour12 >= 12) {
        suffix = 'PM';
        if (hour12 > 12) {
            hour12 -= 12;
        }
    }
    return minute ? `${hour12}:${minute} ${suffix}` : `${hour12} ${suffix}`;
};

// ===================
export const calculateOpeningDateAndTime = (singleBusinessDetails) => {
    const today = new Date().toLocaleString('en-US', { weekday: 'long' });

    if (
        !singleBusinessDetails ||
        !singleBusinessDetails.openingTimes ||
        !singleBusinessDetails.openingTimes[today]
    ) {
        return null;
    }

    const isOpen =
        singleBusinessDetails.openingTimes[today].isOpened === 'true';
    console.log(isOpen);
    const openingTime = isOpen
        ? singleBusinessDetails.openingTimes[today].from
        : getNextOpeningTime(singleBusinessDetails, today);

    if (!openingTime) {
        return null;
    }

    const formattedDay = new Date().toLocaleDateString('en-US', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
    });
    const formattedTime = new Date(`01 Jan 2024 ${openingTime}`).toLocaleString(
        'en-US',
        {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        }
    );

    return { date: formattedDay, time: formattedTime };
};

function getNextOpeningTime(singleBusinessDetails, today) {
    let nextOpenDay = today;
    const days = Object.keys(singleBusinessDetails.openingTimes);
    let nextDayIndex = (new Date().getDay() + 1) % 7;

    while (
        singleBusinessDetails.openingTimes[nextOpenDay].isOpened === 'false'
    ) {
        nextOpenDay = days[nextDayIndex];
        nextDayIndex = (nextDayIndex + 1) % 7;
    }

    return singleBusinessDetails.openingTimes[nextOpenDay].from;
}

// ===================
// Categorized slots
export const categorizeSlots = (slots) => {
    const morningSlots = [];
    const afternoonSlots = [];
    const eveningSlots = [];
    const otherSlots = [];

    const morningStart = '08';
    const morningEnd = '12';
    const afternoonStart = '12';
    const afternoonEnd = '17';
    const eveningStart = '17';
    const eveningEnd = '21';

    slots.forEach((slot) => {
        const start = slot.split('-')[0];
        const startTime = start.split(':')[0];
        const end = slot.split('-')[1];
        const endTime = end.split(':')[0];

        if (
            startTime >= morningStart &&
            startTime < morningEnd &&
            endTime <= morningEnd
        ) {
            morningSlots.push(slot);
        } else if (
            startTime >= afternoonStart &&
            startTime < afternoonEnd &&
            endTime <= afternoonEnd
        ) {
            afternoonSlots.push(slot);
        } else if (
            startTime >= eveningStart &&
            startTime < eveningEnd &&
            endTime <= eveningEnd
        ) {
            eveningSlots.push(slot);
        } else {
            otherSlots.push(slot);
        }
    });

    const categorizedSlots = {};

    if (morningSlots.length > 0) {
        categorizedSlots.Morning = {
            range: '08:00am to 12:00pm',
            slots: morningSlots,
        };
    }

    if (afternoonSlots.length > 0) {
        categorizedSlots.Afternoon = {
            range: '12:00pm to 05:00pm',
            slots: afternoonSlots,
        };
    }

    if (eveningSlots.length > 0) {
        categorizedSlots.Evening = {
            range: '05:00pm to 09:00pm',
            slots: eveningSlots,
        };
    }

    if (otherSlots.length > 0) {
        categorizedSlots['Other Slots'] = { slots: otherSlots };
    }

    return categorizedSlots;

    // return {
    //   Morning: { range: "08:00am to 12:00pm", slots: morningSlots },
    //   Afternoon: { range: "12:00pm to 05:00pm", slots: afternoonSlots },
    //   Evening: { range: "05:00pm to 09:00pm", slots: eveningSlots },
    //   ["Other Slots"]: { slots: otherSlots },
    // };
};

export const shortToFullDayMap = {
    Sun: 'Sunday',
    Mon: 'Monday',
    Tue: 'Tuesday',
    Wed: 'Wednesday',
    Thu: 'Thursday',
    Fri: 'Friday',
    Sat: 'Saturday',
};

export const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

const countryList = getCountryDataList();
let filteredCountryList = countryList.filter(
    (item) =>
        item.iso2 !== 'DO' &&
        item.iso2 !== 'PR' &&
        item.iso2 !== 'XK' &&
        item.iso2 !== 'KZ'
);
export const options = filteredCountryList.map((country) => ({
    value: country.name,
    label: country.name,
    code: `+${country.phone}`,
    iso: country.iso2,
    flag: <Flag height={14} code={country.iso2} />,
}));

export const isBoolean = (value) => {
    if (typeof value === 'boolean') {
        return value;
    } else if (typeof value === 'string') {
        return value.toLowerCase() === 'true';
    } else {
        return false;
    }
};

export const endTime = (time) => {
    const startTime = moment(time, 'HH:mm');
    const endTime = startTime.clone().add(30, 'minutes');
    return moment(endTime).format('hh:mm');
};

export const isReviewable = (item) => {
    if (
        (item?.status === 'completed' ||
            item?.status === 'cancelled' ||
            item?.status === 'canceled' ||
            item?.status === 'rejected') &&
        !item?.review
    ) {
        return true;
    } else {
        return false;
    }
};

export const averageRating = (data) => {
    if (!Array.isArray(data)) {
        throw new Error('Input must be an array');
    }

    const { totalRating, totalAppointmentsWithReview } = data.reduce(
        (acc, appointment) => {
            if (
                appointment.review &&
                typeof appointment.review.rating === 'number'
            ) {
                acc.totalRating += appointment.review.rating;
                acc.totalAppointmentsWithReview++;
            }
            return acc;
        },
        { totalRating: 0, totalAppointmentsWithReview: 0 }
    );

    const averageRating =
        totalAppointmentsWithReview > 0
            ? totalRating / totalAppointmentsWithReview
            : 0;

    const averageRatingOutOf5 = Math.min(5, Math.max(0, averageRating));

    console.log('averageRatingOutOf5: ', averageRatingOutOf5);
    return averageRatingOutOf5;
};
export const businessAverageRating = (data) => {
    if (!Array.isArray(data)) {
        throw new Error('Input must be an array');
    }

    const validRatings = data
        .map((review) => review.rating)
        .filter(
            (rating) => typeof rating === 'number' && rating >= 0 && rating <= 5
        );

    if (validRatings.length === 0) {
        return 'N/A';
    }

    const totalRating = validRatings.reduce((acc, rating) => acc + rating, 0);

    const averageRating = totalRating / validRatings.length;

    return averageRating.toFixed(1);
};
