import moment from "moment";
import React from "react";
import avatar from '../../assets/images/avatar.png';

const ReviewCard = ({ item }) => {
  return (
    <div className="review-card d-flex justify-content-between text-nowrap mt-3">
      <div className="d-flex text-nowrap">
        <img className="review-avatar me-3" src={item?.user?.profile_photo_path || avatar} />
        <div className="d-flex flex-column text-wrap me-1">
          <p className="m-0 f-16 fw-medium poppins c-0f151d ">{item?.user?.name}</p>
          <p className="m-0 f-14 poppins c-667085  mt-2">{item?.description}</p>
          <div className="d-flex justify-content-start align-items-center mt-2">
            <h6 className={`m-0 c-0f151d poppins f-12 fw-normal lh-16`}>{item?.rating?.toFixed(1)}</h6>
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mx-1"
            >
              <g clipPath="url(#clip0_252_296)">
                <path
                  d="M2.44884 11.6623C2.40084 11.6623 2.35284 11.6383 2.30484 11.6143C2.23284 11.5663 2.18484 11.4463 2.20884 11.3503L3.26484 7.41428L0.0968438 4.84628C0.000843818 4.79828 -0.0231562 4.67828 0.000843815 4.58228C0.0248438 4.48628 0.120844 4.41428 0.216844 4.41428L4.29684 4.19828L5.76084 0.382281C5.80884 0.310281 5.90484 0.238281 6.00084 0.238281C6.09684 0.238281 6.19284 0.310281 6.21684 0.382281L7.68084 4.19828L11.7608 4.41428C11.8568 4.41428 11.9528 4.48628 11.9768 4.58228C12.0008 4.67828 11.9768 4.77428 11.9048 4.84628L8.73684 7.41428L9.79284 11.3503C9.81684 11.4463 9.79284 11.5423 9.69684 11.6143C9.62484 11.6623 9.50484 11.6863 9.43284 11.6143L6.00084 9.40628L2.56884 11.6143C2.52084 11.6623 2.49684 11.6623 2.44884 11.6623Z"
                  fill="#334155"
                />
              </g>
              <defs>
                <clipPath id="clip0_252_296">
                  <rect width="12" height="12" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>{" "}
        </div>
      </div>
      <div>
        <p className="m-0 f-16 poppins c-667085 ">
          {moment(item?.createdAt).format('LL')}
        </p>
      </div>
    </div>
  );
};

export default ReviewCard;
