import Cookies from 'js-cookie';

export const isArray = (array) => {
    try {
        return !!Array.isArray(array);
    } catch (e) {
        return false;
    }
};

export const isAuthenticated = () => !!Cookies.get('__tisume');

export const setSession = (data) => {
    const __session = {
        __token: data.token,
    };

    Cookies.set('__tisume', JSON.stringify(__session), { expires: 30 });
};

export const destroySession = async () => {
    if (isAuthenticated()) {
        Cookies.remove('__tisume');
        localStorage.clear();
        // setTimeout(() => {
        //     window.location.reload();
        // }, 1000);
    } else {
        return false;
    }
};

export const getSession = () =>
    isAuthenticated() ? JSON.parse(Cookies.get('__tisume')) : false;

export const getToken = () =>
    isAuthenticated() ? 'Bearer ' + getSession().__token : null;
