import React, { lazy } from 'react';
import propTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { isAuthenticated } from '../../helpers/functions';
import { connect } from 'react-redux';
import withAdminLayout from '../../layout/withAdminLayout';
import { useSelector } from 'react-redux';
import moment from 'moment';

const Stripe = lazy(() => import('../../container/stripe'));

function ProtectedRoute({ component, path, hasSubscription }) {
    const { userData } = useSelector((state) => state.user);
    // return isAuthenticated() ? <Route component={component} path={path} /> : <Redirect to="/login" />;
    return isAuthenticated() && userData?.role === 'business' ? (
        hasSubscription ? (
            <Route component={component} path={path} />
        ) : (
            <Redirect to="/pricing-plan" />
        )
    ) : (
        <Redirect to="/login" />
    );
}

ProtectedRoute.propTypes = {
    component: propTypes.object.isRequired,
    path: propTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
    hasSubscription:
        state.user.userData?.subscription !== null &&
        moment
            .unix(state.user.userData?.subscription.expired_at)
            .isAfter(moment()),
});

export default connect(mapStateToProps)(ProtectedRoute);
