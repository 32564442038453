import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SingleChat from "./SingleChat";

const ChatCards = ({
  toggle,
  setToggle,
  setChatMob,
  chatOnClick,
  selectedChat,
}) => {
  const { allChats } = useSelector((state) => state.chat);

  const [activeIndex, setActiveIndex] = useState(null);

  const handleCardClick = (index, item) => {
    // setActiveIndex(index === activeIndex ? null : index);
    // returnSelectedChat(item)
    // setChatMob(true)
    chatOnClick(item);
  };

  console.log("Toggle..", toggle);
  return (
    <div className="position-relative">
      <div
        className="togglebtn d-none d-md-flex"
        onClick={() => {
          setToggle(!toggle);
        }}
      >
        <svg
          className={toggle ? "arrow-rotate " : ""}
          width="8"
          height="15"
          viewBox="0 0 8 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.87702 14.3307L0.496813 7.95045C0.437492 7.8912 0.390432 7.82083 0.358325 7.74338C0.326216 7.66592 0.309691 7.5829 0.309691 7.49905C0.309691 7.41521 0.326216 7.33218 0.358325 7.25473C0.390432 7.17727 0.437492 7.10691 0.496813 7.04765L6.87702 0.667445C6.99674 0.547726 7.15911 0.480469 7.32842 0.480469C7.49773 0.480469 7.6601 0.547726 7.77982 0.667445C7.89954 0.787163 7.9668 0.949537 7.9668 1.11884C7.9668 1.28815 7.89954 1.45053 7.77982 1.57024L1.85022 7.49905L7.77982 13.4279C7.8391 13.4871 7.88612 13.5575 7.9182 13.635C7.95029 13.7124 7.9668 13.7954 7.9668 13.8793C7.9668 13.9631 7.95029 14.0461 7.9182 14.1236C7.88612 14.201 7.8391 14.2714 7.77982 14.3307C7.72054 14.3899 7.65017 14.437 7.57272 14.469C7.49527 14.5011 7.41225 14.5176 7.32842 14.5176C7.24459 14.5176 7.16158 14.5011 7.08413 14.469C7.00667 14.437 6.9363 14.3899 6.87702 14.3307Z"
            fill="#667085"
          />
        </svg>
      </div>
      <div className="chat-list border rounded-start-4">
        {allChats?.map((item, index) => (
          <SingleChat
            item={item}
            index={index}
            activeIndex={activeIndex}
            handleCardClick={handleCardClick}
            toggle={toggle}
            selectedChat={selectedChat}
          />
        ))}
      </div>
    </div>
  );
};

export default ChatCards;
