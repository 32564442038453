import React, { useEffect } from 'react';
import ServiceCard from './ServiceCard';
import Slider from 'react-slick';
import { LeftArrow, RightArrow } from '../../container/home/svgs';
import { useRef } from 'react';
import BusinessCards from '../../components/BusinessCards';
import { useState } from 'react';
const NearByYou = ({ data }) => {
    const sliderRef = useRef(null);
    const [slidesToShow, setSlidesToShow] = useState(4);

    const handlePrev = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPrev();
        }
    };

    const handleNext = () => {
        if (sliderRef.current) {
            sliderRef.current.slickNext();
        }
    };

    const settings = {
        dots: false,
        infinite: data.length > 1,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: data.length > 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: data.length > 1,
                },
            },
            {
                breakpoint: 380,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: data.length > 1,
                },
            },
        ],
    };

    const updateSlidesToShow = () => {
        const width = window.innerWidth;
        if (width < 380) {
            setSlidesToShow(1);
        } else if (width < 600) {
            setSlidesToShow(2);
        } else if (width < 1024) {
            setSlidesToShow(2);
        } else {
            setSlidesToShow(4);
        }
    };

    useEffect(() => {
        updateSlidesToShow();
        window.addEventListener('resize', updateSlidesToShow);
        return () => {
            window.removeEventListener('resize', updateSlidesToShow);
        };
    }, []);

    return (
        <div className="mb-md-0 mb-3 overflow-hidden position-relative">
            <p className="mb-4 mt-0 mt-md-3 poly f-36">Near by you</p>
            <div className="near-by-you">
                <div className="near-by-you-inner">
                    <Slider ref={sliderRef} {...settings}>
                        {data &&
                            data?.map((item, idx) => {
                                return (
                                    <div key={idx} className="col-12 col-md-4">
                                        <BusinessCards key={idx} item={item} />
                                    </div>
                                );
                            })}
                    </Slider>
                </div>
            </div>
            {data.length >= slidesToShow && (
                <>
                    <div className="carousel-left-btn">
                        <button
                            className="btn-left-arrow pr-20"
                            type="button"
                            onClick={handlePrev}
                        >
                            <LeftArrow />
                        </button>
                    </div>
                    <div className="carousel-right-btn">
                        <button
                            className="btn-left-arrow pr-20"
                            type="button"
                            onClick={handleNext}
                        >
                            <RightArrow />
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};

export default NearByYou;
