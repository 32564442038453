import React, { useEffect, useState } from "react";
import "./services.css";
import BreadCrum from "../breadcrum/BreadCrum";
import ServiceHeader from "./ServiceHeader";
import SingleService from "./single-service-card";
import ContinueCard from "./continue-card";
import { useSelector } from "react-redux";
import { calculateOpeningDateAndTime } from "../../helpers/constants";
import { useDispatch } from "react-redux";
import {
  onSelectedServicesChange,
  resetSelectedServices,
} from "../../redux/services/servicesActions";

const Service = () => {
  const dispatch = useDispatch();
  const { singleBusinessDetails, selectedServices } = useSelector(
    (state) => state.services
  );

  const [category, setCategory] = useState("");
  const [categoryData, setCategoryData] = useState([]);

  const handleCategoryChange = (item) => {
    setCategory(item.category);
    setCategoryData(item.services);
  };

  const handleSelectedServices = (item) => {
    let isAlready =
      selectedServices && selectedServices?.find((ele) => ele.id == item.id);

    if (isAlready) {
      dispatch(
        onSelectedServicesChange(
          selectedServices.filter((ele) => ele.id !== item.id)
        )
      );
    } else {
      dispatch(onSelectedServicesChange([...selectedServices, item]));
    }
  };

  useEffect(() => {
    const firstCategory =
      singleBusinessDetails?.services &&
      Object.entries(singleBusinessDetails?.services)[0];
    if (firstCategory) {
      const [category, data] = firstCategory;
      setCategory(category);
      setCategoryData(data.services);
    }
  }, [singleBusinessDetails]);

  useEffect(() => {
    dispatch(resetSelectedServices());
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container mb-5">
      <BreadCrum
        items={[
          { title: "Home", path: "/home" },
          {
            title: "Business Details",
            path: `/business-details/${singleBusinessDetails?.id}`,
          },
          {
            title: "Services",
            path: `/business-details/${singleBusinessDetails?.id}/services`,
          },
        ]}
      />

      <div>
        {/* <div className="d-flex justify-content-between align-items-center">
          <p className="mb-4 mt-3 poly f-36 me-2  ">Services</p>{" "}
          <p className="m-0 c-3A475C fs-16 poppins cp  d-md-none d-block">
            <u>See More</u>
          </p>
        </div> */}
        <div className="row">
          <div className="col-lg-8 col-md-7">
            <ServiceHeader
              services={singleBusinessDetails?.services}
              handleCategoryChange={handleCategoryChange}
              category={category}
            />
            {categoryData?.map((item, index) => {
              return (
                <SingleService
                  selectedServices={selectedServices}
                  handleSelectedServices={handleSelectedServices}
                  item={item}
                  key={index}
                />
              );
            })}
          </div>
          <div className="col-lg-4 col-md-5">
            <ContinueCard
              selectedServices={selectedServices}
              businessDetails={singleBusinessDetails}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Service;
