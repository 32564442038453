import axios from "axios";
import Cookies from "js-cookie";
import { getToken } from "../helpers/functions";

export const baseURL = process.env.REACT_APP_BASE_URL;
export const axiosInstance = axios.create({
  baseURL,
});

const ResponseInterceptor = (response) => {
  return response;
};

const RequestInterceptor = (config) => {
  config.headers.Authorization = "null";
  if (Cookies.get("__tisume")) {
    config.headers.Authorization = getToken();
  }
  return config;
};

axiosInstance.interceptors.request.use(RequestInterceptor);

axiosInstance.interceptors.response.use(ResponseInterceptor, (error) => {
  const expectedErrors = error.response && error.response.status >= 400 && error.response.status < 509;
  const logoutKey = error.response?.data?.isSuspended;
  const status = error.response?.status;
  let errorToastShown = false;

  if (!expectedErrors) {
    return Promise.reject(error.response);
  } else if ((status === 401 || status === 403 || logoutKey) && !errorToastShown) {
    errorToastShown = true;
    setTimeout(() => {
      localStorage.removeItem("auth");
      localStorage.clear()
      const tokenCookieName = "__tisume";
      const pastDate = new Date(0);
      document.cookie = `${tokenCookieName}=; expires=${pastDate.toUTCString()}; path=/`;
      window.location.href = "/login";
    }, 3000);
    return Promise.reject(error);
  } else {
    return Promise.reject(error.response);
  }
});

export default axiosInstance;
