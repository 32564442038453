export const UPDATE_CHAT = "UPDATE_CHAT";
export const CHAT_PENDING = "CHAT_PENDING";
export const CHAT_REJECTED = "CHAT_REJECTED";
export const GET_CHAT_BY_USER_ID = "GET_CHAT_BY_USER_ID";
export const NOTIFICATION_CHAT = "NOTIFICATION_CHAT";
export const SET_INITIALIZING_CHAT_LOADING = "SET_INITIALIZING_CHAT_LOADING";
export const SET_CHAT_LOADING = "SET_CHAT_LOADING";
export const SET_GET_MESSAGES_LOADING = "SET_GET_MESSAGES_LOADING";
export const GET_MESSAGES_BY_CHAT_ID = "GET_MESSAGES_BY_CHAT_ID";
export const SET_SEND_MESSAGE_LOADER = "SET_SEND_MESSAGE_LOADER";
