import React, { useEffect } from 'react';
import { Badge, Spin } from 'antd';
import FeatherIcon from 'feather-icons-react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import { useSelector } from 'react-redux';
import { AtbdTopDropdwon } from './auth-info-style';
import { Popover } from '../../popup/popup';
import Heading from '../../heading/heading';
import { notification } from '../../../assets/images/sidebarIcons';
import { useDispatch } from 'react-redux';
import {
    getNotifications,
    readNotifications,
} from '../../../redux/notifications/notificationsAction';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';

function NotificationBox() {
    const { notifications, isLoading } = useSelector(
        (state) => state.notifications
    );

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(getNotifications());
    }, []);

    const handleClick = (id, url) => {
        dispatch(
            readNotifications(id, () => {
                history.push(`/${url}`);
            })
        );
    };

    const { rtl } = useSelector((state) => {
        return {
            rtl: state?.ChangeLayoutMode?.rtlData,
        };
    });

    function renderThumb({ style, ...props }) {
        const thumbStyle = {
            borderRadius: 6,
            backgroundColor: '#F1F2F6',
        };
        return <div style={{ ...style, ...thumbStyle }} {...props} />;
    }

    const renderTrackVertical = () => {
        const thumbStyle = {
            position: 'absolute',
            width: '6px',
            transition: 'opacity 200ms ease 0s',
            opacity: 0,
            [rtl ? 'left' : 'right']: '2px',
            bottom: '2px',
            top: '2px',
            borderRadius: '3px',
        };
        return <div className="hello" style={thumbStyle} />;
    };

    function renderView({ style, ...props }) {
        const customStyle = {
            marginRight: rtl && 'auto',
            [rtl ? 'marginLeft' : 'marginRight']: '-17px',
        };
        return <div {...props} style={{ ...style, ...customStyle }} />;
    }

    renderThumb.propTypes = {
        style: PropTypes.shape(PropTypes.object),
    };

    renderView.propTypes = {
        style: PropTypes.shape(PropTypes.object),
    };

    const content = (
        <AtbdTopDropdwon className="atbd-top-dropdwon">
            <Heading as="h5" className="atbd-top-dropdwon__title">
                <span className="title-text">Notifications</span>
                <Badge
                    className="badge-success"
                    count={notifications?.length}
                />
            </Heading>
            <Scrollbars
                autoHeight
                autoHeightMin={290}
                autoHide
                renderThumbVertical={renderThumb}
                renderView={renderView}
                renderTrackVertical={renderTrackVertical}
            >
                <ul className="atbd-top-dropdwon__nav notification-list overflow-auto">
                    {Array.isArray(notifications) &&
                        notifications?.map((item) => (
                            <li
                                key={item?.id}
                                onClick={() =>
                                    handleClick(item?.id, item?.redirectUrl)
                                }
                            >
                                <Link to="#" className='text-decoration-none'>
                                    <div className="atbd-top-dropdwon__content notifications">
                                        <div className="notification-icon bg-secondary">
                                            {item?.user?.profile_photo_path ? <img src={item.user.profile_photo_path} alt="icon" /> : <FeatherIcon icon="share" />}
                                        </div>
                                        <div className="notification-content d-flex">
                                            <div className="notification-text">
                                                <Heading as="h5">
                                                    <span>{item?.message}</span>
                                                </Heading>
                                                <p>{moment(item?.createdAt).format('hh:mm A DD MMM YYYY')}</p>
                                            </div>

                                            <div className="notification-status">
                                                <Badge dot />
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </li>
                        ))}
                </ul>
            </Scrollbars>
            {/* <Link className="btn-seeAll" to="#">
                See all incoming activity
            </Link> */}
        </AtbdTopDropdwon>
    );

    return (
        <div className="notification cp">
            <Popover
                placement="bottomLeft"
                content={isLoading ? <Spin /> : content}
                action="click"
            >
                <div className="notification-container">
                    <img src={notification} alt="icon" />
                    <div className="notification-counter">
                        {notifications && notifications?.length}
                    </div>
                </div>
            </Popover>
        </div>
    );
}

export default NotificationBox;
