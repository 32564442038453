import React from "react";
import { useHistory } from "react-router-dom";

const BreadCrum = ({ items }) => {
  const history = useHistory();

  const handleRedirect = (path) => {
    history.push(path);
  };

  return (
    <div className="d-flex py-3">
      {items &&
        items.map((item, index) => (
          <React.Fragment key={index}>
            <p
              className={`mb-0 poppins f-14 ${
                index !== items.length - 1 ? "c-334155" : "c-667085"
              } ${
                index !== items.length - 1 ? "text-decoration-underline cp" : ""
              } ${index !== items.length - 1 ? "fw-medium" : "f-14"} ${
                index !== items.length - 1 ? "mr-2" : ""
              }`}
              onClick={() => {
                if (index !== items.length - 1) {
                  if (item?.action) {
                    item.action()
                  }
                  handleRedirect(item.path);
                }
              }}
            >
              {item.title}
            </p>
            {index !== items.length - 1 && (
              <div
                className={`mx-2 poppins ${
                  index < 1 ? "c-334155" : "c-667085"
                }`}
              >
                &gt;
              </div>
            )}
          </React.Fragment>
        ))}
    </div>
  );
};

export default BreadCrum;
