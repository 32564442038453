import {
    ADD_PAYMENT_ERROR,
    ADD_PAYMENT_START,
    ADD_PAYMENT_SUCCESS,
    REFERRAL_ID,
    UPDATE_CUSTOMER_ERROR,
    UPDATE_CUSTOMER_PENDING,
    UPDATE_CUSTOMER_SUCCESS,
} from './types';

const initialState = {
    isLoading: false,
    isCardLoading: false,
    userData: null,
    searchData: {},
    tempData: null,
    referralId: '',
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'TEMP_DATA':
            return {
                ...state,
                tempData: action.payload,
            };
        case 'SET_USER':
            return {
                ...state,
                userData: action.payload,
            };
        case 'SET_SEARCH_DATA':
            return {
                ...state,
                searchData: action.payload,
            };
        case UPDATE_CUSTOMER_PENDING:
            return {
                ...state,
                isLoading: true,
            };
        case ADD_PAYMENT_START:
            return {
                ...state,
                isCardLoading: true,
            };
        case REFERRAL_ID:
            return {
                ...state,
                referralId: action.payload,
            };
        case UPDATE_CUSTOMER_SUCCESS:
        case UPDATE_CUSTOMER_ERROR:
        case ADD_PAYMENT_SUCCESS:
        case ADD_PAYMENT_ERROR:
            return {
                ...state,
                isLoading: false,
                isCardLoading: false,
            };
        default:
            return state;
    }
};

export default userReducer;
