import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import { NavAuth, UserDropDwon } from './auth-info-style';
import { Popover } from '../../popup/popup';
import { handleLogout } from '../../../redux/authentication/actionCreator';
import { destroySession } from '../../../helpers/functions';
import { setUser } from '../../../redux/users/actionCreator';
import { useSelector } from 'react-redux';
import { persistor } from '../../../redux/store';

function AuthInfo() {
    const { userData } = useSelector((state) => state.user);

    const dispatch = useDispatch();
    const history = useHistory();
    const [state, setState] = useState({
        flag: 'english',
    });

    const clearCookies = () => {
        document.cookie.split(";").forEach((c) => {
            document.cookie = c
                .replace(/^ +/, "")
                .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
        });
    };

    const clearLocalStorage = () => {
        localStorage.clear();
    };

    const SignOut = async () => {
        setUser(null);
        destroySession();
        clearCookies()
        clearLocalStorage()
        await persistor.purge();
        dispatch(handleLogout());
        if (userData?.role === 'business') {
            history.push('/login');
        } else history.push('/login-customer');
    };

    const userContent = (
        <UserDropDwon>
            <div className="user-dropdwon">
                <ul className="user-dropdwon__links">
                    <li>
                        <Link
                            to={userData?.role === 'customer'
                                ? '/customer/profile-setting'
                                : '/dashboard'}
                        >
                            <FeatherIcon icon="user" />
                            {userData?.role === 'customer'
                                ? 'Profile'
                                : 'Dashboard'}
                        </Link>
                    </li>
                    {userData?.role === 'customer' && (
                        <li>
                            <Link
                                to="/customer/upcoming-appointments"
                            >
                                <FeatherIcon icon="calendar" /> Appointments
                            </Link>
                        </li>
                    )}
                    {userData.role === 'business' && (
                        <>
                            <li>
                                <Link to="/dashboard/setting">
                                    <FeatherIcon icon="settings" /> Settings
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/pricing-plan"
                                    className="text-decoration-none"
                                >
                                    <p
                                        className="text- mb-0 fw-semibold"
                                        style={{ width: 24, height: 24 }}
                                    >
                                        &pound;
                                    </p>
                                    <span className='text-decoration-underline ps-1'>Pricing Plan</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/dashboard/app/calendar/month">
                                    <FeatherIcon icon="users" /> Activity
                                </Link>
                            </li>
                            <li>
                                <a
                                    href="mailto:help@tisume.com"
                                    target="_blank"
                                >
                                    <FeatherIcon icon="bell" /> Help
                                </a>
                            </li>
                        </>
                    )}
                </ul>
                <Link
                    className="user-dropdwon__bottomAction"
                    onClick={SignOut}
                    to="#"
                >
                    <FeatherIcon icon="log-out" /> Sign Out
                </Link>
            </div>
        </UserDropDwon>
    );

    const onFlagChangeHandle = (value) => {
        setState({
            ...state,
            flag: value,
        });
    };

    const country = (
        <NavAuth>
            <Link onClick={() => onFlagChangeHandle('english')} to="#">
                <img
                    src={require('../../../static/img/flag/english.png')}
                    alt=""
                />
                <span>English</span>
            </Link>
            <Link onClick={() => onFlagChangeHandle('germany')} to="#">
                <img
                    src={require('../../../static/img/flag/germany.png')}
                    alt=""
                />
                <span>Germany</span>
            </Link>
            <Link onClick={() => onFlagChangeHandle('spain')} to="#">
                <img
                    src={require('../../../static/img/flag/spain.png')}
                    alt=""
                />
                <span>Spain</span>
            </Link>
            <Link onClick={() => onFlagChangeHandle('turky')} to="#">
                <img
                    src={require('../../../static/img/flag/turky.png')}
                    alt=""
                />
                <span>Turky</span>
            </Link>
        </NavAuth>
    );

    return (
        <div>
            <div className="nav-author">
                <Popover
                    placement="bottomRight"
                    content={userContent}
                    action="click"
                >
                    <div className={`d-flex gap-2 align-items-center cp`}>
                        <img
                            className="profile-avatar"
                            src={
                                userData.profile_photo_path
                                    ? userData.profile_photo_path
                                    : 'https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png'
                            }
                        />

                        <h4 className={`f-14 text-black mb-0 text-capitalize`}>
                            {userData.name}
                        </h4>
                    </div>
                </Popover>
            </div>
        </div>
    );
}

export default AuthInfo;
