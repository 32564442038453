import { toast } from 'react-toastify';
import { axiosInstance } from '../../repository/Repository';
import { setUser } from '../users/actionCreator';
import {
    GET_AUTOMATED_SETTING_ERROR,
    GET_AUTOMATED_SETTING_START,
    GET_AUTOMATED_SETTING_SUCCESS,
    GET_DETAILS,
    GET_PENDING,
    GET_PRICING_ERROR,
    GET_PRICING_START,
    GET_PRICING_SUCCESS,
    GET_REJECTED,
    INSTA_RES,
    RESET_INSTA_FILES,
    UPDATE_AUTOMATED_SETTING_ERROR,
    UPDATE_AUTOMATED_SETTING_START,
    UPDATE_AUTOMATED_SETTING_SUCCESS,
    UPDATE_DETAILS,
    UPDATE_PENDING,
    UPDATE_REJECTED,
} from './types';

export const getDetails = () => async (dispatch) => {
    dispatch({ type: GET_PENDING });
    try {
        const response = await axiosInstance.get('/business/');
        dispatch({ type: GET_DETAILS, payload: response.data.user });
        dispatch(setUser(response?.data?.user));
        return response.data;
    } catch (error) {
        console.log('Error Fetching Business Details');
        dispatch({ type: GET_REJECTED });
        throw error;
    }
};

export const updateDetails = (formData) => async (dispatch) => {
    dispatch({ type: UPDATE_PENDING });
    try {
        const response = await axiosInstance.put('/business/update', formData);
        dispatch({ type: UPDATE_DETAILS });
        console.log('UpdateResponse', response.data);
        return response.data;
    } catch (error) {
        console.log('Error Updating Business Details');
        dispatch({ type: UPDATE_REJECTED });
        throw error;
    }
};

export const fetchAutomatedNotificationSettings = () => async (dispatch) => {
    dispatch({ type: GET_AUTOMATED_SETTING_START });
    try {
        const response = await axiosInstance.get(
            '/business/automated-notifications'
        );
        dispatch({
            type: GET_AUTOMATED_SETTING_SUCCESS,
            payload: response.data,
        });
        return response.data;
    } catch (error) {
        dispatch({ type: GET_AUTOMATED_SETTING_ERROR });
        throw error;
    }
};
export const updateAutomatedNotificationSettings =
    (data, message, onSuccess = () => {}) =>
    async (dispatch) => {
        dispatch({ type: UPDATE_AUTOMATED_SETTING_START });
        try {
            const response = await axiosInstance.put(
                '/business/automated-notifications',
                data
            );
            await dispatch(fetchAutomatedNotificationSettings());
            dispatch({ type: UPDATE_AUTOMATED_SETTING_SUCCESS });
            toast.success(message);
            onSuccess();
            return response.data;
        } catch (error) {
            dispatch({ type: UPDATE_AUTOMATED_SETTING_ERROR });
            throw error;
        }
    };

export const buyEmailCradit =
    (data, onSuccess = () => {}, onError = () => {}) =>
    async (dispatch) => {
        try {
            const response = await axiosInstance.post(
                '/business/email-credit/buy',
                data
            );
            await dispatch(getDetails());
            toast.success(response.data?.message);
            onSuccess();
            return response.data;
        } catch (error) {
            toast.error(error?.data?.message);
            onError();
            throw error;
        }
    };

export const delImage = (id) => async (dispatch) => {
    dispatch({ type: UPDATE_PENDING });
    try {
        const response = await axiosInstance.delete(`/business/image/${id}`);
        dispatch({ type: UPDATE_REJECTED });
        console.log('deleteImageResponse', response.data);
        return response.data;
    } catch (error) {
        console.log('Error deleting image');
        dispatch({ type: UPDATE_REJECTED });
        throw error;
    }
};
export const getPricingInfo = (id) => async (dispatch) => {
    dispatch({ type: GET_PRICING_START });
    try {
        const response = await axiosInstance.get(`/stripe/price`);
        dispatch({ type: GET_PRICING_SUCCESS, payload: response.data.data });
        return response.data;
    } catch (error) {
        dispatch({ type: GET_PRICING_ERROR });
        throw error;
    }
};

export const handleInsta = (data) => ({
    type: INSTA_RES,
    payload: data,
});
export const resetInstaFiles = () => ({
    type: RESET_INSTA_FILES,
});
