export const LeftArrow = () => (
    <svg
        width="50"
        height="50"
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="25" cy="25" r="24.5" fill="white" stroke="#E8E8E8" />
        <path
            d="M17.2851 25.5704C16.8946 25.1799 16.8946 24.5467 17.2851 24.1562L23.649 17.7922C24.0396 17.4017 24.6727 17.4017 25.0633 17.7922C25.4538 18.1827 25.4538 18.8159 25.0633 19.2064L19.4064 24.8633L25.0633 30.5201C25.4538 30.9107 25.4538 31.5438 25.0633 31.9344C24.6727 32.3249 24.0396 32.3249 23.649 31.9344L17.2851 25.5704ZM33.983 23.8633L34.983 23.8633L34.983 25.8633L33.983 25.8633L33.983 23.8633ZM17.9922 23.8633L33.983 23.8633L33.983 25.8633L17.9922 25.8633L17.9922 23.8633Z"
            fill="#252525"
        />
    </svg>
);

export const RightArrow = () => (
    <svg
        width="50"
        height="50"
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="25" cy="25" r="24.5" fill="white" stroke="#E8E8E8" />
        <path
            d="M32.7149 24.4257C33.1054 24.8162 33.1054 25.4494 32.7149 25.8399L26.351 32.2039C25.9604 32.5944 25.3273 32.5944 24.9367 32.2039C24.5462 31.8134 24.5462 31.1802 24.9367 30.7897L30.5936 25.1328L24.9367 19.476C24.5462 19.0854 24.5462 18.4523 24.9367 18.0617C25.3273 17.6712 25.9604 17.6712 26.351 18.0617L32.7149 24.4257ZM16.017 26.1328L15.017 26.1328L15.017 24.1328L16.017 24.1328L16.017 26.1328ZM32.0078 26.1328L16.017 26.1328L16.017 24.1328L32.0078 24.1328L32.0078 26.1328Z"
            fill="#252525"
        />
    </svg>
);

export const Star = () => (
    <svg
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M23.8157 11.6165L19.2352 15.614L20.6073 21.5656C20.6799 21.8767 20.6592 22.2023 20.5477 22.5018C20.4363 22.8012 20.239 23.0611 19.9807 23.249C19.7223 23.437 19.4142 23.5445 19.0951 23.5584C18.7759 23.5722 18.4597 23.4916 18.186 23.3267L12.9952 20.1782L7.81549 23.3267C7.54185 23.4916 7.22567 23.5722 6.90648 23.5584C6.58729 23.5445 6.27925 23.437 6.02088 23.249C5.7625 23.0611 5.56526 22.8012 5.45381 22.5018C5.34235 22.2023 5.32163 21.8767 5.39424 21.5656L6.76432 15.6201L2.18284 11.6165C1.94052 11.4075 1.76529 11.1316 1.67914 10.8235C1.59299 10.5153 1.59974 10.1885 1.69856 9.88417C1.79737 9.57982 1.98384 9.31141 2.23459 9.11261C2.48534 8.91381 2.7892 8.79347 3.10807 8.76667L9.14698 8.24362L11.5042 2.62113C11.6273 2.3261 11.835 2.0741 12.101 1.89684C12.367 1.71958 12.6796 1.625 12.9992 1.625C13.3189 1.625 13.6314 1.71958 13.8975 1.89684C14.1635 2.0741 14.3711 2.3261 14.4942 2.62113L16.8586 8.24362L22.8955 8.76667C23.2144 8.79347 23.5182 8.91381 23.769 9.11261C24.0197 9.31141 24.2062 9.57982 24.305 9.88417C24.4038 10.1885 24.4106 10.5153 24.3244 10.8235C24.2383 11.1316 24.063 11.4075 23.8207 11.6165H23.8157Z"
            fill="#FFC700"
        />
    </svg>
);

export const GrayStar = () => (
    <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_252_296)">
            <path
                d="M2.44884 11.6623C2.40084 11.6623 2.35284 11.6383 2.30484 11.6143C2.23284 11.5663 2.18484 11.4463 2.20884 11.3503L3.26484 7.41428L0.0968438 4.84628C0.000843818 4.79828 -0.0231562 4.67828 0.000843815 4.58228C0.0248438 4.48628 0.120844 4.41428 0.216844 4.41428L4.29684 4.19828L5.76084 0.382281C5.80884 0.310281 5.90484 0.238281 6.00084 0.238281C6.09684 0.238281 6.19284 0.310281 6.21684 0.382281L7.68084 4.19828L11.7608 4.41428C11.8568 4.41428 11.9528 4.48628 11.9768 4.58228C12.0008 4.67828 11.9768 4.77428 11.9048 4.84628L8.73684 7.41428L9.79284 11.3503C9.81684 11.4463 9.79284 11.5423 9.69684 11.6143C9.62484 11.6623 9.50484 11.6863 9.43284 11.6143L6.00084 9.40628L2.56884 11.6143C2.52084 11.6623 2.49684 11.6623 2.44884 11.6623Z"
                fill="#334155"
            />
        </g>
        <defs>
            <clipPath id="clip0_252_296">
                <rect width="12" height="12" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
