import {
    GET_CUSTOMER_ERROR,
    GET_CUSTOMER_START,
    GET_CUSTOMER_SUCCESS,
} from './types';

const initialState = {
    customers: [],
    isLoading: false,
};

const customerReducers = (state = initialState, action) => {
    const { type, payload, err } = action;
    switch (type) {
        case GET_CUSTOMER_START:
            return {
                ...state,
                isLoading: true,
                customers: [],
            };
        case GET_CUSTOMER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                customers: payload,
            };
        case GET_CUSTOMER_ERROR:
            return {
                ...state,
                isLoading: false,
                customers: [],
            };

        default:
            return state;
    }
};

export default customerReducers;
