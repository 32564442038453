import React from "react";

const ServiceHeader = ({ services, category, handleCategoryChange }) => {
  return (
    <div className="services-header d-flex flex-row ">
      {services &&
        Object.entries(services)?.map(([key, value]) => {
          return (
            <div
              key={key}
              className={`services-header-item d-flex align-items-center cp me-2 ${
                key === category ? "services-header-item-active" : ""
              }`}
              onClick={() =>
                handleCategoryChange({
                  category: key,
                  services: value.services,
                })
              }
            >
              {/* <img src={icon} className="me-3" />{" "} */}
              <p className="m-0 text-nowrap poppins">{key}</p>
            </div>
          );
        })}
    </div>
  );
};

export default ServiceHeader;
