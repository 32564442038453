import { axiosInstance } from "../../repository/Repository";
import { GET_NOTIFICATIONS, READ_NOTIFICATIONS, NOTIFICATIONS_PENDING, NOTIFICATIONS_REJECTED } from "./types";

export const getNotifications = () => async (dispatch) => {
    dispatch({ type: NOTIFICATIONS_PENDING });
    try {
        const response = await axiosInstance.get('/inAppnotifications/unviewed');
        dispatch({ type: GET_NOTIFICATIONS, payload: response.data });
        return response.data;
    } catch (error) {
        console.log('Error getting notification');
        dispatch({ type: NOTIFICATIONS_REJECTED });
        throw error;
    }
};

export const setReadNotificationsLoader = (val) => async (dispatch) => {
    dispatch({ type: READ_NOTIFICATIONS, payload: val });
};

export const readNotifications = (id, onSuccess = () => { }) => async (dispatch) => {
    dispatch(setReadNotificationsLoader(true));
    try {
        const response = await axiosInstance.put(`/inAppnotifications/${id}`);
        dispatch(setReadNotificationsLoader(false));
        onSuccess()
        return response.data;
    } catch (error) {
        console.log('Error getting notification');
        dispatch(setReadNotificationsLoader(false));
        throw error;
    }
};