import React from 'react';
import './profilesetting.css';
import BreadCrum from '../features/breadcrum/BreadCrum';
import PersonalInfo from './profile-settings/PersonalInfo';
import PaymentMethods from './profile-settings/PaymentMethods';
import { DeleteSvg } from './profile-settings/svgs';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import {
    getCustomerDetails,
    setUser,
    updateCustomerPersonalInfo,
    updateUserStatus,
} from '../redux/users/actionCreator';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { Button, Modal, Spin } from 'antd';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { destroySession } from '../helpers/functions';
import { persistor } from '../redux/store';
import { handleLogout } from '../redux/authentication/actionCreator';
import { toast } from 'react-toastify';

const Profilesetting = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { userData, isLoading, isCardLoading } = useSelector(
        (state) => state.user
    );

    const [messageNotifications, setMessageNotifications] = useState(true);
    const [emailNotifications, setEmailNotifications] = useState(true);
    const [loadingButton, setLoadingButton] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeactivateModalOpen, setIsDeactivateModalOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const handleChange = (e) => {
        let { name, checked } = e.target;
        setLoadingButton(name);
        let formData = new FormData();
        let id = userData?.id;
        let message = 'Notifications setting updated successfully';
        formData.append(name, checked);
        dispatch(
            updateCustomerPersonalInfo(id, formData, message, () => {
                setLoadingButton('');
                if (!checked) {
                    setIsOpen(true)
                }
            })
        );
    };

    const SignOut = async () => {
        setUser(null);
        destroySession();
        await persistor.purge();
        dispatch(handleLogout());
        history.push('/register');
    };

    useEffect(() => {
        if (userData) {
            setMessageNotifications(
                Boolean(userData?.text_message_notifications)
            );
            setEmailNotifications(
                Boolean(userData?.email_marketing_notifications)
            );
        }
    }, [userData]);

    useEffect(() => {
        dispatch(getCustomerDetails());
    }, []);
    return (
        <>
            <div className="container mt-5">
                <BreadCrum
                    items={[
                        { title: 'Home', path: '/home' },
                        {
                            title: 'My Profile',
                            path: '/customer/profile-setting',
                        },
                    ]}
                />
            </div>
            <div className="container profilecontainer gap-4 my-5">
                <PersonalInfo />
                <div className=" col-12 col-md-8 poppins ">
                    <PaymentMethods />
                    <div className="border-bottom ">
                        <div className="pt-4">
                            <p className="c-0f151d poppins f-16 lh-24 fw-medium ">
                                My Notifications
                            </p>
                            <p className="c-667085 f-14 poppins lh-20  ">
                                We’ll send you updates about your appointments,
                                news and marketing offrs.
                            </p>
                        </div>
                        {/* <div className="d-flex justify-content-between my-2">
                            <div>
                                <p className="c-0f151d poppins f-16 lh-24 fw-medium ">
                                    Text message notifications
                                </p>
                                <p className="c-667085 f-14 poppins lh-20  ">
                                    Receive texts based on your sender's
                                    settings
                                </p>
                            </div>
                            <div class="form-check form-switch">
                                <label class="switch">
                                    <input
                                        name="text_message_notifications"
                                        checked={messageNotifications}
                                        onChange={handleChange}
                                        type="checkbox"
                                    />
                                    {isLoading &&
                                        loadingButton ===
                                        'text_message_notifications' ? (
                                        <Spin />
                                    ) : (
                                        <span class="slider"></span>
                                    )}
                                </label>
                            </div>
                        </div> */}
                        <div className="d-flex justify-content-between my-2">
                            <div>
                                <p className="c-0f151d poppins f-16 lh-24 fw-medium ">
                                    Email marketing notifications
                                </p>
                                <p className="c-667085 f-14 poppins  lh-20  ">
                                    Receive offers and news via email
                                </p>
                            </div>
                            <div class="form-check form-switch">
                                <label class="switch">
                                    <input
                                        name="email_marketing_notifications"
                                        type="checkbox"
                                        checked={emailNotifications}
                                        onChange={handleChange}
                                    />
                                    {isLoading &&
                                        loadingButton ===
                                        'email_marketing_notifications' ? (
                                        <Spin />
                                    ) : (
                                        <span class="slider"></span>
                                    )}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-lg-between my-3 flex-column flex-lg-row">
                        <div>
                            <p className="c-0f151d poppins fw-medium f-16 lh-24">
                                {userData?.status === 'active'
                                    ? 'Deactivate account'
                                    : 'Activate account'}
                            </p>
                            {/* <p>Are you sure you want to leave Tisume?</p> */}
                        </div>
                        <div
                            className="d-flex gap-2"
                            role="button"
                            onClick={() => setIsDeactivateModalOpen(true)}
                        >
                            {userData?.status === 'active' ? (
                                <>
                                    <DeleteSvg />
                                    <span className="text-danger">
                                        Deactivate my account
                                    </span>
                                </>
                            ) : (
                                <span className="text-success">
                                    Activate my account
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="d-flex gap-3 ">
                        <button
                            className="poppins f-16 lh-24 c-334155 rounded-4 py-lg-3 py-sm-2 px-sm-3  px-5 border-0 w-100"
                            onClick={() =>
                                history.push('/customer/my-favourites')
                            }
                        >
                            My favorites
                        </button>
                        <button
                            className="poppins f-16 lh-24 text-white bg-btn rounded-4 py-lg-3 py-sm-2 px-sm-3 px-5 border-0 w-100"
                            onClick={() => setIsModalOpen(true)}
                        >
                            List your business
                        </button>
                    </div>
                </div>
            </div>
            <Modal
                open={isOpen}
                onCancel={() => setIsOpen(false)}
                footer={null}
            >
                <p className='poppins f-14 c-334155'>
                    You will not receive updates on your appointments booking when you switch it off
                </p>
            </Modal>

            <Modal
                open={isModalOpen}
                onCancel={() => setIsModalOpen(false)}
                footer={null}
            >
                <h4>Confirmation required</h4>
                <p className="mb-5">
                    Would you like to log out from your customer account and
                    register as a business?
                </p>
                <div className="d-flex gap-3 justify-content-end">
                    <button
                        className="btn btn-outline"
                        onClick={() => setIsModalOpen(false)}
                    >
                        Cancel
                    </button>
                    <button className="btn btn-success" onClick={SignOut}>
                        Proceed
                    </button>
                </div>
            </Modal>
            <Modal
                open={isDeactivateModalOpen}
                onCancel={() => setIsDeactivateModalOpen(false)}
                footer={null}
            >
                <h4>Confirmation required</h4>
                <p className="mb-5">
                    {`Are you sure you want to ${userData?.status === 'active'
                        ? 'deactivate'
                        : 'activate'
                        } your account?`}
                </p>
                <div className="d-flex gap-3 justify-content-end">
                    <button
                        className="btn btn-outline"
                        onClick={() => setIsDeactivateModalOpen(false)}
                    >
                        Cancel
                    </button>
                    <button
                        className={`btn ${userData?.status === 'active'
                            ? 'btn-danger'
                            : 'btn-success'
                            }  `}
                        disabled={isCardLoading}
                        onClick={() => {
                            let status =
                                userData?.status === 'active'
                                    ? 'inactive'
                                    : 'active';
                            dispatch(
                                updateUserStatus(status, () => {
                                    setIsDeactivateModalOpen(false);
                                })
                            );
                        }}
                    >
                        {isCardLoading ? <Spin /> : 'Proceed'}
                    </button>
                </div>
            </Modal>
        </>
    );
};

export default Profilesetting;
