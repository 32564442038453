import { combineReducers } from 'redux';
import authReducer from './authentication/reducers';
import ChangeLayoutMode from './themeLayout/reducers';
// import { headerSearchReducer } from './headerSearch/reducers';
import chartContentReducer from './chartContent/reducers';
import userReducer from './users/reducers';
import Calender from './calendar/reducers';
import servicesReducers from './services/servicesReducers';
import detailsReducer from './businessAccount/detailsReducer';
import appointmentReducer from './appointments/appointmentReducer';
import chatReducer from './Chat/chatReducer';
import customerReducers from './customer/customerReducers';
import chartsReducer from './Charts/chartsReducer';
import reviewsReducer from './Reviews/reviewsReducer';
import notificationReducer from './notifications/notificationsReducer';

export const appReducer = combineReducers({
    // headerSearchData: headerSearchReducer,
    notifications: notificationReducer,
    user: userReducer,
    chartContent: chartContentReducer,
    auth: authReducer,
    ChangeLayoutMode,
    Calender,
    services: servicesReducers,
    businessDetails: detailsReducer,
    appointments: appointmentReducer,
    chat: chatReducer,
    customers: customerReducers,
    charts: chartsReducer,
    reviews: reviewsReducer,
});

const rootReducers = (state, action) => {
    if (action.type === 'LOGOUT_SUCCESS') {
        return appReducer(undefined, action);
    }
    return appReducer(state, action);
};

export default rootReducers;
