import React, { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Axios from '../../config/Axios';
import AuthLayout from './auth-layout';
import { Spin } from 'antd';
import { SignIn } from '../../redux/authentication/actionCreator';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { validateEmail, validatePassword } from '../../helpers/constants';
import logo from "../../assets/images/Appointments/Logo-Header.png";


const Login = () => {
    const isLoading = useSelector((state) => state.auth.loading);

    const dispatch = useDispatch();
    const history = useHistory();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const passwordSuccess = queryParams.get('passwordSuccess');

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState({});

    const handleCredentials = (e) => {
        const { name, value } = e.target;

        if (name === 'email') {
            setEmail(value);
            if (validateEmail(value, setErrors)) {
                setErrors((prev) => ({ ...prev, [name]: '' }));
            }
        } else if (name === 'password') {
            setPassword(value);
            if (validatePassword(value, setErrors)) {
                setErrors((prev) => ({ ...prev, [name]: '' }));
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors({});
        const [isEmailValid, isPasswordValid] = await Promise.all([
            validateEmail(email, setErrors),
            validatePassword(password, setErrors),
        ]);
        if (isEmailValid && isPasswordValid) {
            dispatch(
                SignIn(
                    { email, password },
                    history,
                    () => {
                        toast.success('Logged in successfully');
                    },
                    (err) => {
                        // if (err.data.statusCode === 400) {
                        //     document
                        //         .getElementById('resend-email')
                        //         .classList.remove('d-none');
                        // }
                    }
                )
            );
        }
    };

    // const handleResendEmail = async () => {
    //     document.getElementById('resend-email').classList.add('d-none');

    //     const response = await Axios.post('user/resend-email', { email });

    //     console.log(response.data);

    //     if (response.data.success) {
    //         setMessage('Email sent successfully');
    //     }
    // };

    return (
        <AuthLayout>
            <form onSubmit={handleSubmit}>
                <Link className="d-flex justify-content-center mb-4" to="/home">
                    <img src={logo} alt="Logo" style={{ width: '132px' }} />
                </Link>
                <div className={`col-12 px-0 pt-lg-0 pt-100`}>
                    <h5
                        className={`c-334155 poly f-36 fw-400 lh-48 m-0 text-center`}
                    >
                        Tisume for Business
                    </h5>
                </div>

                <div className={`col-12 px-0 pb-md-30 pb-3`}>
                    <h6
                        className={`c-667085 poppins f-14 fw-400 lh-20 m-0 text-center`}
                    >
                        please fill the information below
                    </h6>
                </div>

                {message || passwordSuccess ? (
                    <div className={`centered-container`}>
                        <div className={`success-badge mb-2`}>
                            <span>
                                {message
                                    ? message
                                    : 'Password updated successfully. You can now log in.'}
                                !
                            </span>
                        </div>
                    </div>
                ) : null}

                <div className={`col-12 px-md-0 position-relative`}>
                    <div className="mb-20">
                        <label
                            htmlFor="email"
                            className="form-label c-667085 poppins f-14 fw-400 lh-20"
                        >
                            Email Id <span className={`text-danger`}>*</span>
                        </label>
                        <input
                            type="email"
                            className="form-control input-filed"
                            id="email"
                            name="email"
                            value={email}
                            onChange={handleCredentials}
                            placeholder="Your Email"
                        />

                        {errors.email && (
                            <p className="text-danger fs-12">{errors.email}</p>
                        )}
                    </div>
                </div>

                <div className={`col-12 px-md-0 position-relative`}>
                    <div className="mb-20">
                        <label
                            htmlFor="password"
                            className="form-label c-667085 poppins f-14 fw-400 lh-20"
                        >
                            Password <span className={`text-danger`}>*</span>
                        </label>
                        <input
                            type="password"
                            className="form-control input-filed"
                            id="password"
                            name="password"
                            value={password}
                            onChange={handleCredentials}
                            placeholder="Your Password"
                        />

                        {errors.password && (
                            <p className="text-danger fs-12">
                                {errors.password}
                            </p>
                        )}

                        {errors.credentials && (
                            <p className={`password-error-text`}>
                                {errors.credentials}
                            </p>
                        )}
                    </div>
                </div>

                {/* <div id="resend-email" className="d-none">
                    <p>
                        Your email verification is pending. Please verify your
                        email to proceed.
                    </p>
                    <button
                        className={`btn btn-create-acc mb-20`}
                        onClick={handleResendEmail}
                    >
                        Resend Email
                    </button>
                </div> */}

                <div className={`col-12 px-md-0`}>
                    <button
                        type="submit"
                        className={`btn btn-create-acc mb-20`}
                        disabled={isLoading}
                    >
                        {isLoading ? <Spin /> : 'Sign In'}
                    </button>
                </div>

                <div className={`col-12 px-md-0 pb-lg-0 pb-5`}>
                    <Link
                        to={'/register'}
                        style={{ paddingTop: '11px', paddingBottom: '11px' }}
                        className={`btn btn-outline mb-20 border rounded-pill w-100`}
                        disabled={isLoading}
                    >
                        Sign up
                    </Link>
                </div>

                <div className={`col-12 px-md-0 pb-lg-0 pb-5`}>
                    <h6 className="m-0 c-667085 poppins f-14 f-mobile-16 fw-normal lh-20 text-center mb-3">
                        <Link
                            to="/login-customer"
                            className="c-334155 poppins f-14 f-mobile-16 lh-20 ms-2"
                        >
                            Sign up/Sign In as a customer{' '}
                        </Link>
                    </h6>
                    <h6
                        className={`m-0 c-667085 poppins f-14 f-mobile-16 fw-normal lh-20 text-center`}
                    >
                        <Link
                            to="/forget-password"
                            className={`text-decoration-none c-334155 poppins f-14 f-mobile-16 fw-medium lh-20 ms-2`}
                        >
                            Forget Password?
                        </Link>
                    </h6>
                </div>
            </form>
        </AuthLayout>
    );
};

export default Login;
