import React, { useState } from "react";
import { useSelector } from "react-redux";
import { urlRegex } from "../../helpers/constants";

const Business = ({ handleNext, handleChange, formData, message }) => {
    const [errors, setErrors] = useState({})

    const handleNextClick = () => {
        if (!formData.name) {
            setErrors(prev => ({ ...prev, name: "Business name should not be empty" }));
        }
        else if (formData.website && !urlRegex.test(formData.website)) {
            setErrors(prev => ({ ...prev, website: "Provide valid url 'https://www.yourwebsite.com'" }));
        } else {
            handleNext();
        }
    }

    return (
        <div className={`container-fluid max-w-md-605`}>
            <div className={`row justify-content-center pb-md-40 pb-3`}>
                <div className={`col-md-12 px-0 text-center pt-4 pb-16`}>
                    <h5 className={`m-0 c-334155 poly f-36px fw-normal lh-48`}>
                        What’s your business name?
                    </h5>
                </div>
                <div className={`col-md-8 col-12 px-0 text-center lh-0`}>
                    <span className={`m-0 c-667085 poppins f-14 fw-normal lh-20`}>
                        This is the brand name your client will see. Your billing
                        and legal name can be added later.
                    </span>
                </div>
            </div>
            {/*{message &&*/}
            {/*    <div className={`centered-container`}>*/}
            {/*        <div className={`success-badge mb-2`}>*/}
            {/*            <span>{message}!</span>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*}*/}
            <div className={`row`}>
                <div className={`col-12 px-md-0`}>
                    <div className={`card-step`}>
                        <div className={`row pb-md-40 pb-3`}>
                            <div className={`col-md-12 pb-20 position-relative`}>
                                <label htmlFor="businessName" className="form-label c-667085 poppins f-14 fw-400 lh-20">
                                    Business name <span className={`text-danger`}>*</span>
                                </label>
                                <input
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    className="form-control input-filed"
                                    id="businessName"
                                    placeholder="Enter your Business name"
                                />
                                {errors.name && <p className={`password-error-text`}>{errors.name}</p>}
                            </div>
                            <div className={`col-md-12 pb-20 position-relative`}>
                                <label htmlFor="website" className="form-label c-667085 poppins f-14 fw-400 lh-20">
                                    Website
                                </label>
                                <input
                                    type="text"
                                    className="form-control input-filed"
                                    name="website"
                                    value={formData.website}
                                    onChange={handleChange}
                                    id="website"
                                    placeholder="https://www.yourwebsite.com"
                                />
                                {errors.website && <p className={`password-error-text`}>{errors.website}</p>}
                            </div>
                        </div>
                        <div className={`row`}>
                            <div className={`col-md-12 pl-md-7-5 pb-md-0 pb-3 order-md-1 order-0`} onClick={handleNextClick}>
                                <button className={`btn btn-next`}>
                                    Next
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Business
