import { APPOINTCHART_PENDING, APPOINTCHART_DATA, APPOINTCHART_REJECTED, CUSTCHART_PENDING, CUSTCHART_DATA, CUSTCHART_REJECTED } from './types';

const initialState = {
    appointmentChartData: [],
    appointmentChartLoading: false,
    customersChartData: [],
    customersChartLoading: false,
};

const chartsReducer = (state = initialState, action) => {
    switch (action.type) {

        //Appointments Chart
        case APPOINTCHART_PENDING:
            return {
                ...state,
                appointmentChartLoading: true,
            };
        case APPOINTCHART_DATA:
            return {
                ...state,
                appointmentChartLoading: false,
                appointmentChartData: action.payload,
            };
        case APPOINTCHART_REJECTED:
            return {
                ...state,
                appointmentChartLoading: false,
            };

        //Customers Chart
        case CUSTCHART_PENDING:
            return {
                ...state,
                customersChartLoading: true,
            };
        case CUSTCHART_DATA:
            return {
                ...state,
                customersChartLoading: false,
                customersChartData: action.payload,
            };
        case CUSTCHART_REJECTED:
            return {
                ...state,
                customersChartLoading: false,
            };

        default:
            return state;
    }
};

export default chartsReducer;
