import React, { useState } from "react";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  BOOK_APPOINTMENT_ERROR,
  BOOK_APPOINTMENT_START,
} from "../redux/appointments/types";

const StripeCard = ({
  getToken,
  setGetToken,
  setStripeToken,
  notes,
  setNotes,
  setPaymentMethodId
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const [cardHolderName, setCardHolderName] = useState("");

  const handleSubmit = async () => {
    dispatch({ type: BOOK_APPOINTMENT_START });
    if (!stripe || !elements) {
      setGetToken(false);
      dispatch({ type: BOOK_APPOINTMENT_ERROR });
      return;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);
    const cardCvcElement = elements.getElement(CardCvcElement);

    if (
      !cardNumberElement._complete ||
      !cardExpiryElement._complete ||
      !cardCvcElement._complete ||
      !cardHolderName
    ) {
      toast.error("Please fill in all the required fields.");
      setGetToken(false);
      dispatch({ type: BOOK_APPOINTMENT_ERROR });
      return;
    }

    try {
      const { token, error } = await stripe.createToken(cardNumberElement, {
        name: cardHolderName,
      });
      const { paymentMethod, error:paymentMethodError } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardNumberElement,
        billing_details: {
          name: cardHolderName,
        },
      });
      if (error || paymentMethodError) {
        toast.error(error?.message || "An error occurred. Please try again.");
        setGetToken(false);
        dispatch({ type: BOOK_APPOINTMENT_ERROR });
      } else {
        // console.log(paymentMethod)
        setStripeToken(token.id);
        setPaymentMethodId(paymentMethod.id);
        setGetToken(false);
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
      setGetToken(false);
      dispatch({ type: BOOK_APPOINTMENT_ERROR });
      console.error(error);
    }
  };

  useEffect(() => {
    if (getToken) {
      handleSubmit();
    }
  }, [getToken]);

  return (
    <div>
      <form>
        <div className="mb-2">
          <label className="poppins c-667085 f-14 lh-20">
            Name on card
            <span className="text-danger ">*</span>
          </label>
        </div>
        <input
          type="text"
          value={cardHolderName}
          onChange={(e) => setCardHolderName(e.target.value)}
          placeholder="Alena Franci"
          className="w-100 border border-color-c-e1e6ef poppins rounded-5 py-3 px-3 lh-20 f-16 c-0f151d"
        />

        <div className="mb-2 mt-2">
          <label className="poppins c-667085 f-14 lh-20">Card Number</label>
        </div>
        <div className="position-relative">
          <CardNumberElement
            options={{ placeholder: "4242 4242 4242 4242" }}
            className="w-100 border border-color-c-e1e6ef poppins rounded-5 py-3  lh-24 f-16 c-0f151d px-3"
          />
          <div className="position-absolute  end-0 top-50 translate-middle-y  text-black me-4">
            <svg
              width="18"
              height="14"
              viewBox="0 0 18 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.5 0.75H1.5C1.16848 0.75 0.850537 0.881696 0.616116 1.11612C0.381696 1.35054 0.25 1.66848 0.25 2V12C0.25 12.3315 0.381696 12.6495 0.616116 12.8839C0.850537 13.1183 1.16848 13.25 1.5 13.25H16.5C16.8315 13.25 17.1495 13.1183 17.3839 12.8839C17.6183 12.6495 17.75 12.3315 17.75 12V2C17.75 1.66848 17.6183 1.35054 17.3839 1.11612C17.1495 0.881696 16.8315 0.75 16.5 0.75ZM16.5 2V3.875H1.5V2H16.5ZM16.5 12H1.5V5.125H16.5V12ZM15.25 10.125C15.25 10.2908 15.1842 10.4497 15.0669 10.5669C14.9497 10.6842 14.7908 10.75 14.625 10.75H12.125C11.9592 10.75 11.8003 10.6842 11.6831 10.5669C11.5658 10.4497 11.5 10.2908 11.5 10.125C11.5 9.95924 11.5658 9.80027 11.6831 9.68306C11.8003 9.56585 11.9592 9.5 12.125 9.5H14.625C14.7908 9.5 14.9497 9.56585 15.0669 9.68306C15.1842 9.80027 15.25 9.95924 15.25 10.125ZM10.25 10.125C10.25 10.2908 10.1842 10.4497 10.0669 10.5669C9.94973 10.6842 9.79076 10.75 9.625 10.75H8.375C8.20924 10.75 8.05027 10.6842 7.93306 10.5669C7.81585 10.4497 7.75 10.2908 7.75 10.125C7.75 9.95924 7.81585 9.80027 7.93306 9.68306C8.05027 9.56585 8.20924 9.5 8.375 9.5H9.625C9.79076 9.5 9.94973 9.56585 10.0669 9.68306C10.1842 9.80027 10.25 9.95924 10.25 10.125Z"
                fill="currentColor"
              />
            </svg>
          </div>
        </div>
        <div className="poppins d-flex gap-5 justify-content-center align-items-center mt-2">
          <div className="w-100">
            <div className="mb-2 mt-2">
              <label className="c-667085 f-14 lh-20">Expiry date</label>
            </div>

            <CardExpiryElement className="border border-color-c-e1e6ef py-3 px-3 rounded-5 w-100" />
          </div>
          <div className="w-100">
            <div className="mb-2 mt-2">
              <label className="c-667085 f-14 lh-20">Security code</label>
            </div>

            <CardCvcElement
              options={{ placeholder: "CVV" }}
              className="form-control form-control-solid inputNumber border border-color-c-e1e6ef py-3 px-3 rounded-5 w-100"
            />
          </div>
        </div>
        <div className="my-4">
          <h1 className="poppins fw-600 f-18 lh-24 c-0f151d">
            Your payment method
          </h1>
        </div>
        <div className="d-flex gap-3 mt-4">
          <div>
            <svg
              width="42"
              height="14"
              viewBox="0 0 42 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.3999 13.0332L17.5332 1.0332H20.8666L18.7999 13.0332H15.3999Z"
                fill="#3C58BF"
              />
              <path
                d="M15.3999 13.0332L18.1332 1.0332H20.8666L18.7999 13.0332H15.3999Z"
                fill="#293688"
              />
              <path
                d="M30.8666 1.16615C30.2 0.899479 29.1333 0.632812 27.8 0.632812C24.4666 0.632812 22.0666 2.29948 22.0666 4.69948C22.0666 6.49948 23.7333 7.43281 25.0666 8.03281C26.4 8.63281 26.8 9.03281 26.8 9.56614C26.8 10.3661 25.7333 10.7661 24.8 10.7661C23.4666 10.7661 22.7333 10.5661 21.6 10.0995L21.1333 9.89948L20.6666 12.6328C21.4666 12.9661 22.9333 13.2995 24.4666 13.2995C28 13.2995 30.3333 11.6328 30.3333 9.09948C30.3333 7.69948 29.4666 6.63281 27.4666 5.76615C26.2666 5.16615 25.5333 4.83281 25.5333 4.23281C25.5333 3.69948 26.1333 3.16615 27.4666 3.16615C28.6 3.16615 29.4 3.36615 30 3.63281L30.3333 3.76615L30.8666 1.16615Z"
                fill="#3C58BF"
              />
              <path
                d="M30.8666 1.16615C30.2 0.899479 29.1333 0.632812 27.8 0.632812C24.4666 0.632812 22.6666 2.29948 22.6666 4.69948C22.6666 6.49948 23.7333 7.43281 25.0666 8.03281C26.4 8.63281 26.8 9.03281 26.8 9.56614C26.8 10.3661 25.7333 10.7661 24.8 10.7661C23.4666 10.7661 22.7333 10.5661 21.6 10.0995L21.1333 9.89948L20.6666 12.6328C21.4666 12.9661 22.9333 13.2995 24.4666 13.2995C28 13.2995 30.3333 11.6328 30.3333 9.09948C30.3333 7.69948 29.4666 6.63281 27.4666 5.76615C26.2666 5.16615 25.5333 4.83281 25.5333 4.23281C25.5333 3.69948 26.1333 3.16615 27.4666 3.16615C28.6 3.16615 29.4 3.36615 30 3.63281L30.3333 3.76615L30.8666 1.16615Z"
                fill="#293688"
              />
              <path
                d="M36.6 1.0332C35.8 1.0332 35.2 1.09987 34.8667 1.89987L29.8667 13.0332H33.4667L34.1334 11.0332H38.4L38.8 13.0332H42L39.2 1.0332H36.6ZM35.0667 9.0332C35.2667 8.4332 36.4 5.49987 36.4 5.49987C36.4 5.49987 36.6667 4.76654 36.8667 4.29987L37.0667 5.4332C37.0667 5.4332 37.7334 8.43321 37.8667 9.09987H35.0667V9.0332Z"
                fill="#3C58BF"
              />
              <path
                d="M37.4 1.0332C36.6 1.0332 36 1.09987 35.6667 1.89987L29.8667 13.0332H33.4667L34.1334 11.0332H38.4L38.8 13.0332H42L39.2 1.0332H37.4ZM35.0667 9.0332C35.3334 8.36654 36.4 5.49987 36.4 5.49987C36.4 5.49987 36.6667 4.76654 36.8667 4.29987L37.0667 5.4332C37.0667 5.4332 37.7334 8.43321 37.8667 9.09987H35.0667V9.0332Z"
                fill="#293688"
              />
              <path
                d="M9.26672 9.43295L8.93339 7.69961C8.33339 5.69961 6.40006 3.49961 4.26672 2.43294L7.26672 13.0996H10.8667L16.2667 1.09961H12.6667L9.26672 9.43295Z"
                fill="#3C58BF"
              />
              <path
                d="M9.26672 9.43295L8.93339 7.69961C8.33339 5.69961 6.40006 3.49961 4.26672 2.43294L7.26672 13.0996H10.8667L16.2667 1.09961H13.3334L9.26672 9.43295Z"
                fill="#293688"
              />
              <path
                d="M0 1.0332L0.6 1.16654C4.86667 2.16654 7.8 4.69987 8.93334 7.69987L7.73334 2.0332C7.53334 1.2332 6.93333 1.0332 6.2 1.0332H0Z"
                fill="#FFBC00"
              />
              <path
                d="M0 1.0332C4.26667 2.0332 7.8 4.6332 8.93334 7.6332L7.8 2.89987C7.6 2.09987 6.93333 1.6332 6.2 1.6332L0 1.0332Z"
                fill="#F7981D"
              />
              <path
                d="M0 1.0332C4.26667 2.0332 7.8 4.6332 8.93334 7.6332L8.13334 5.0332C7.93333 4.2332 7.66667 3.4332 6.73334 3.09987L0 1.0332Z"
                fill="#ED7C00"
              />
              <path
                d="M12.6 9.0319L10.3334 6.76523L9.26671 9.29857L9.00004 7.6319C8.40004 5.6319 6.46671 3.4319 4.33337 2.36523L7.33337 13.0319H10.9334L12.6 9.0319ZM18.8 13.0319L15.9334 10.0986L15.4 13.0319H18.8ZM26.4667 8.89857C26.7334 9.16523 26.8667 9.36524 26.8 9.6319C26.8 10.4319 25.7334 10.8319 24.8 10.8319C23.4667 10.8319 22.7334 10.6319 21.6 10.1652L21.1334 9.96524L20.6667 12.6986C21.4667 13.0319 22.9334 13.3652 24.4667 13.3652C26.6 13.3652 28.3334 12.7652 29.3334 11.6986L26.4667 8.89857ZM30.3334 13.0319H33.4667L34.1334 11.0319H38.4L38.8 13.0319H42L40.8667 8.16524L36.8667 4.29857L37.0667 5.36523C37.0667 5.36523 37.7334 8.36524 37.8667 9.0319H35.0667C35.3334 8.36524 36.4 5.49857 36.4 5.49857C36.4 5.49857 36.6667 4.76524 36.8667 4.29857"
                fill="#051244"
              />
            </svg>
          </div>
          <div>
            <svg
              width="34"
              height="22"
              viewBox="0 0 34 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.625 21.625C16.493 21.625 21.25 16.868 21.25 11C21.25 5.13197 16.493 0.375 10.625 0.375C4.75697 0.375 0 5.13197 0 11C0 16.868 4.75697 21.625 10.625 21.625Z"
                fill="#F93232"
              />
              <path
                d="M23.375 0.375C20.9886 0.375 18.7935 1.17188 17.0213 2.5H17V2.51487C16.5793 2.8315 16.1925 3.1885 15.8238 3.5625H18.1762C18.5034 3.89506 18.8052 4.251 19.0846 4.625H14.9069C14.6494 4.96453 14.4127 5.31935 14.1982 5.6875H19.7912C19.9909 6.03175 20.179 6.38238 20.3416 6.75H13.6574C13.5047 7.09664 13.371 7.45133 13.2568 7.8125H20.7411C20.8516 8.15887 20.9387 8.51481 21.0152 8.875H12.9795C12.9083 9.2235 12.8488 9.57625 12.8127 9.9375H21.1863C21.2277 10.286 21.25 10.6398 21.25 11H12.75C12.75 11.3602 12.7787 11.7119 12.8138 12.0625H21.1884C21.148 12.4227 21.0896 12.7765 21.0163 13.125H12.9806C13.0539 13.4873 13.1484 13.8401 13.2579 14.1875H20.7421C20.628 14.5487 20.4942 14.9034 20.3416 15.25H13.6574C13.8178 15.6155 13.9984 15.9704 14.1982 16.3125H19.7912C19.5763 16.6809 19.3389 17.0358 19.0804 17.375H14.9069C15.1895 17.749 15.4944 18.1049 15.8228 18.4375H18.1762C17.8105 18.8152 17.4173 19.1653 17 19.4851C18.835 20.8742 21.0736 21.6256 23.375 21.625C29.2432 21.625 34 16.8671 34 11C34 5.13287 29.2432 0.375 23.375 0.375Z"
                fill="#FED049"
              />
            </svg>
          </div>
          <div>
            <svg
              width="30"
              height="20"
              viewBox="0 0 30 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="29.1667" height="20" fill="#306FC5" />
            </svg>
          </div>
        </div>
        <div className="mb-4">
          <div className="mb-2 mt-5 ">
            <label className="poppins c-667085 f-14 lh-20">Booking notes</label>
          </div>
          <div>
            <textarea
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              type="textarea"
              rows="6"
              placeholder="Include comments or request about your booking"
              className="w-100 border border-color-c-e1e6ef poppins rounded-5 pt-3   px-3 lh-24 f-16 c-0f151d"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default StripeCard;
