import React from 'react';
import { GrayStar } from '../container/home/svgs';
import heart from '../assets/images/favourites/heart.png';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { removeFromFav } from '../redux/services/servicesActions';
import { businessAverageRating } from '../helpers/constants';

const BusinessCards = ({ item, type }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    return (
        <div
            className={`col-12 px-16 pb-md-0 pb-3 mb-3 h-100 text-decoration-none`}
        >
            <div className="card">
                <img
                    src={item?.pictures && item?.pictures[0]?.photo_path}
                    className="container-content-img rounded-0 rounded-top-3 overflow-hidden"
                    alt="Card Image"
                />
                {type && type === 'favourite' && (
                    <button
                        className="service-card-icon"
                        onClick={() => dispatch(removeFromFav(item?.id))}
                    >
                        <img src={heart} />
                    </button>
                )}

                <div
                    className="card-body p-20 cp d-flex flex-column justify-content-between"
                    onClick={() => history.push(`/business-details/${item.id}`)}
                >
                    <div
                        className={`d-flex gap-3 align-items-baseline justify-content-between lh-0 pb-3`}
                    >
                        <h5
                            title={item?.name}
                            className={`c-0f151d f-16 fw-normal lh-24 poppins mb-0 text-truncate`}
                        >
                            {item?.name}
                        </h5>
                        <div className={`d-flex gap-1 px-0px`}>
                            <h6
                                className={`m-0 c-0f151d poppins f-12 fw-normal lh-16`}
                            >
                                {businessAverageRating(item?.reviews || [])}
                            </h6>
                            <GrayStar />
                            <h6
                                className={`m-0 c-0f151d poppins f-12 fw-normal lh-16`}
                            >
                                ({item?.reviews?.length || 0})
                            </h6>
                        </div>
                    </div>
                    <div>
                        <div
                            className={`d-flex flex-wrap gap-1 align-items-baseline px-0px overflow-hidden mb-3`}
                            style={{ height: '56px' }}
                        >
                            {Array.isArray(item?.categories) &&
                                item.categories.slice(0, 4).map((cat, i) => (
                                    <span
                                        className="badge c-334155 poppins f-12 fw-normal lh-16 py-4rem px-12 bg-gold br-16 d-flex align-items-center"
                                        key={i}
                                    >
                                        {cat}
                                    </span>
                                ))}
                            {Array.isArray(item?.categories) &&
                                item.categories.length > 4 && (
                                    <p className="mb-0 fs-14">
                                        +{item?.categories.length - 4}
                                        <span className="text-decoration-underline ">
                                            more
                                        </span>
                                    </p>
                                )}
                        </div>
                        <h6
                            title={item?.address}
                            className={`c-667085 f-14 fw-normal lh-20 poppins m-0 text-truncate`}
                        >
                            {item?.address}
                        </h6>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BusinessCards;
